@charset "UTF-8";
@import url(../node_modules/cookieconsent/build/cookieconsent.min.css);
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 40px;
  height: 29px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 5px;
  background-color: transparent;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -12px;
}

.hamburger-inner::after {
  bottom: -12px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 12px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 12px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 12px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 12px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -24px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -12px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -24px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -12px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2.5px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 12px;
  transition: opacity 0.15s 0.4s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 24px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 12px, 0) rotate(135deg);
  transition-delay: 0.1s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -24px, 0) rotate(-270deg);
  transition-delay: 0.1s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2.5px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 12px;
  transition: opacity 0.15s 0.4s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 24px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 12px, 0) rotate(-135deg);
  transition-delay: 0.1s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -24px, 0) rotate(270deg);
  transition-delay: 0.1s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.2s 0.25s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 12px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.2s 0.25s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 12px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2.5px;
}

.hamburger--slider .hamburger-inner::before {
  top: 12px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.hamburger--slider .hamburger-inner::after {
  top: 24px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 12px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.7142857143px, -7px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -24px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2.5px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 12px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 24px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 12px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.7142857143px, -7px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -24px, 0) rotate(90deg);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2.5px;
  transition: background-color 0s 0.15s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 12px;
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 24px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.32s;
  background-color: transparent;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 12px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 12px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -24px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -12px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.32s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease, opacity 0.1s 0.14s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/**
 * Burger
 */
/**
 * Select parts of the burger
 */
/**
 * Burger animations
 */
.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul li {
  margin: 0;
  padding: 0;
}

/**
 * @file
 * Generic theme-independent base styles.
 */
/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list */
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
  zoom: 1;
  /* IE7 */
}

/* Animated throbber */
html.js input.form-autocomplete {
  background-image: url(../../misc/throbber-inactive.png);
  background-position: 100% center;
  /* LTR */
  background-repeat: no-repeat;
}

html.js input.throbbing {
  background-image: url(../../misc/throbber-active.gif);
  background-position: 100% center;
  /* LTR */
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js fieldset.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

html.js fieldset.collapsed .fieldset-wrapper {
  display: none;
}

fieldset.collapsible {
  position: relative;
}

fieldset.collapsible .fieldset-legend {
  display: block;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
body.drag {
  cursor: move;
}

.draggable a.tabledrag-handle {
  cursor: move;
  float: left;
  /* LTR */
  height: 1.7em;
  margin-left: -1em;
  /* LTR */
  overflow: hidden;
  text-decoration: none;
}

a.tabledrag-handle:hover {
  text-decoration: none;
}

a.tabledrag-handle .handle {
  background: url(../../misc/draggable.png) no-repeat 6px 9px;
  height: 13px;
  margin: -0.4em 0.5em;
  /* LTR */
  padding: 0.42em 0.5em;
  /* LTR */
  width: 13px;
}

a.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

div.indentation {
  float: left;
  /* LTR */
  height: 1.7em;
  margin: -0.4em 0.2em -0.4em -0.4em;
  /* LTR */
  padding: 0.42em 0 0.42em 0.6em;
  /* LTR */
  width: 20px;
}

div.tree-child {
  background: url(../../misc/tree.png) no-repeat 11px center;
  /* LTR */
}

div.tree-child-last {
  background: url(../../misc/tree-bottom.png) no-repeat 11px center;
  /* LTR */
}

div.tree-child-horizontal {
  background: url(../../misc/tree.png) no-repeat -11px center;
}

.tabledrag-toggle-weight-wrapper {
  text-align: right;
  /* LTR */
}

/**
 * TableHeader behavior.
 *
 * @see tableheader.js
 */
table.sticky-header {
  background-color: #fff;
  margin-top: 0;
}

/**
 * Progress behavior.
 *
 * @see progress.js
 */
/* Bar */
.progress .bar {
  background-color: #fff;
  border: 1px solid;
}

.progress .filled {
  background-color: #000;
  height: 1.5em;
  width: 5px;
}

.progress .percentage {
  float: right;
  /* LTR */
}

/* Throbber */
/**
 * Inline items.
 */
.container-inline div,
.container-inline label {
  display: inline;
}

/* Fieldset contents always need to be rendered as block. */
.container-inline .fieldset-wrapper {
  display: block;
}

/**
 * Prevent text wrapping.
 */
.nowrap {
  white-space: nowrap;
}

/**
 * For anything you want to hide on page load when JS is enabled, so
 * that you can use the JS to control visibility and avoid flicker.
 */
html.js .js-hide {
  display: none;
}

/**
 * Hide elements from all users.
 *
 * Used for elements which should not be immediately displayed to any user. An
 * example would be a collapsible fieldset that will be expanded with a click
 * from a user. The effect of this class can be toggled with the jQuery show()
 * and hide() functions.
 */
.element-hidden {
  display: none;
}

/**
 * Hide elements visually, but keep them available for screen-readers.
 *
 * Used for information required for screen-reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 * "!important" is used to prevent unintentional overrides.
 */
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

/**
 * The .element-focusable class extends the .element-invisible class to allow
 * the element to be focusable when navigated to via the keyboard.
 */
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on November 16, 2017 9:49 AM */
@font-face {
  font-family: 'Century Gothic Italic';
  src: url("../fonts/CenturyGothic-Italic.eot");
  src: local("Century Gothic Italic"), local("CenturyGothic-Italic"), url("../fonts/CenturyGothic-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/CenturyGothic-Italic.woff2") format("woff2"), url("../fonts/CenturyGothic-Italic.woff") format("woff"), url("../fonts/CenturyGothic-Italic.ttf") format("truetype"), url("../fonts/CenturyGothic-Italic.svg#CenturyGothic-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Century Gothic BoldItalic';
  src: url("../fonts/CenturyGothic-BoldItalic.eot");
  src: local("Century Gothic Bold Italic"), local("CenturyGothic-BoldItalic"), url("../fonts/CenturyGothic-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/CenturyGothic-BoldItalic.woff2") format("woff2"), url("../fonts/CenturyGothic-BoldItalic.woff") format("woff"), url("../fonts/CenturyGothic-BoldItalic.ttf") format("truetype"), url("../fonts/CenturyGothic-BoldItalic.svg#CenturyGothic-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Century Gothic Bold';
  src: url("../fonts/CenturyGothic-Bold.eot");
  src: local("Century Gothic Bold"), local("CenturyGothic-Bold"), url("../fonts/CenturyGothic-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/CenturyGothic-Bold.woff2") format("woff2"), url("../fonts/CenturyGothic-Bold.woff") format("woff"), url("../fonts/CenturyGothic-Bold.ttf") format("truetype"), url("../fonts/CenturyGothic-Bold.svg#CenturyGothic-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url("../fonts/CenturyGothic.eot");
  src: local("Century Gothic"), local("CenturyGothic"), url("../fonts/CenturyGothic.eot?#iefix") format("embedded-opentype"), url("../fonts/CenturyGothic.woff2") format("woff2"), url("../fonts/CenturyGothic.woff") format("woff"), url("../fonts/CenturyGothic.ttf") format("truetype"), url("../fonts/CenturyGothic.svg#CenturyGothic") format("svg");
  font-weight: normal;
  font-style: normal;
}

.system-messages {
  background: #000;
}

.system-messages .messages {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  color: #FFF;
}

.system-messages .messages::after {
  clear: both;
  content: "";
  display: block;
}

#content-author-navigation > .tabs {
  background: rgba(0, 0, 0, 0.5);
}

.tabs {
  position: relative;
  z-index: 1;
}

.tabs ul {
  margin: 0;
  padding-left: 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
}

.tabs ul::after {
  clear: both;
  content: "";
  display: block;
}

.tabs ul li {
  display: block;
  text-align: center;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
}

.tabs ul li:first-child {
  display: none;
}

.tabs ul a {
  color: #FFF;
  display: block;
  padding: 30px;
  transition: .25s all ease-out;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: 600;
}

.tabs ul a:hover {
  background: #1a1a1a;
  text-decoration: none;
}

.field-label-inline .field-label,
.field-label-inline .field-items,
.field-label-inline .field-item {
  display: inline;
}

html {
  font-size: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 8px;
  }
}

body {
  font-size: 1.6rem;
}

.is-loading--pdf {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
}

.is-loading--pdf:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999;
}

.is-loading--pdf:after {
  content: "";
  border-radius: 50%;
  width: 5em;
  height: 5em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000;
  border: 0.6em solid rgba(116, 174, 36, 0.4);
  border-left: .6em solid #7bbc00;
  animation: loader 1.5s infinite linear;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inner--wide {
  max-width: 160rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.5rem;
  clear: both;
}

.inner--wide::after {
  clear: both;
  content: "";
  display: block;
}

.inner--narrow {
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.5rem;
  clear: both;
}

.inner--narrow::after {
  clear: both;
  content: "";
  display: block;
}

.front .inner--narrow {
  max-width: 160rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.5rem;
  clear: both;
}

.front .inner--narrow::after {
  clear: both;
  content: "";
  display: block;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

/* close commented backslash hack */
img {
  max-width: 100%;
  display: block;
  height: auto;
}

*:focus {
  outline: none;
}

.ajax-progress-throbber {
  display: none;
}

a {
  text-decoration: none;
}

sup {
  vertical-align: super;
  font-size: smaller;
  line-height: 0;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

textarea,
input[type="text"],
input[type="email"],
input[type="password"] {
  -webkit-appearance: none;
  border-radius: 0;
}

.novation {
  float: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  color: #898989;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}

@media screen and (max-width: 856px) {
  .novation {
    float: left;
    margin-top: 4.4rem;
    margin-left: 3rem;
  }
}

@media screen and (max-width: 366px) {
  .novation {
    margin-left: 0;
  }
}

.novation:hover {
  color: #7bbc00;
}

.encon-logo {
  float: left;
  width: 18.4rem;
  margin-top: 1.3rem;
}

.spacer {
  height: 5.5rem;
}

.spacer2 {
  height: 5.5rem;
}

.spacer3 {
  height: 18.3rem;
}

.align-center {
  text-align: center;
}

.node__content,
.reference-teaser--full,
footer,
.news-teaser--full,
.spacer,
.spacer2 {
  background-color: #ffffff;
  position: relative;
  z-index: 11;
}

.page-node-85 .spacer2 {
  height: 4rem;
}

.clear {
  clear: both;
}

#content-author-navigation {
  position: fixed;
  top: 15.8rem;
  left: 0;
  width: 100%;
  z-index: 11;
}

.logged-in header {
  top: 2.9rem;
}

#map {
  height: 73.5rem;
}

.page {
  background-color: white;
  overflow-y: hidden;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: #808080;
}

.region::after {
  clear: both;
  content: "";
  display: block;
}

.footer {
  padding: 6rem 0;
}

.bot-footer {
  padding-bottom: 6rem;
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  font-family: "Century Gothic";
  font-weight: 300;
  font-size: 1.6rem;
  clear: both;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #838383;
  background: #FFFFFF;
  position: relative;
  font-family: "Century Gothic";
  font-weight: 300;
  font-size: 1.6rem;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 48px 0 17px;
  font-size: 1.6rem;
  line-height: 48px;
  color: #808080;
  height: 48px;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
  color: #808080;
  text-align: center;
  font: 0/0 a;
  *font: 20px/48px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #808080;
  border-bottom: none;
}

.selectric-hover .selectric .button {
  color: #e6e6e6;
}

.selectric-hover .selectric .button:after {
  border-top-color: #e6e6e6;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  border: 1px solid #6a6a6a;
  border-top: none;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
  border: 1px solid #6a6a6a;
  border-bottom: none;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  margin: 17px;
  color: #666;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.selectric-items li.selected {
  color: #808080;
  font-weight: 700;
}

.selectric-items li.highlighted {
  color: #808080;
  font-weight: 700;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  background: #2a2a2a !important;
  width: 5rem;
  height: 5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  right: -10rem;
}

@media screen and (max-width: 1752px) {
  .slick-prev,
  .slick-next {
    right: -2.5rem;
  }
}

.slick-prev:hover,
.slick-next:hover {
  background: #2b2b2b !important;
  color: #ffffff;
}

.slick-prev:before,
.slick-next:before {
  content: none;
}

.slick-prev:after,
.slick-next:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 22'%3E%3Ctitle%3Earrow2%3C/title%3E%3Cpath d='M13,0,10.18,2.83,16.35,9H0v4H16.35l-6.18,6.18L13,22,24,11Z'/%3E%3C/svg%3E");
  width: 3rem;
  height: 3rem;
  background-position: center;
  background-size: 2.5rem;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-prev {
  left: -10rem;
}

@media screen and (max-width: 1752px) {
  .slick-prev {
    left: -2.5rem;
  }
}

.slick-prev:after {
  transform: rotate(180deg) translate(50%, 50%);
  transform-origin: center center;
}

.fancybox-enabled {
  overflow: hidden;
  touch-action: none;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  backface-visibility: hidden;
}

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992;
}

.fancybox-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0f0f11;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  backface-visibility: hidden;
}

.fancybox-container--ready .fancybox-bg {
  opacity: 0.7;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  z-index: 99994;
  transition: opacity 100ms;
  pointer-events: none;
  backface-visibility: hidden;
}

.fancybox-show-controls .fancybox-controls {
  opacity: 1;
}

.fancybox-infobar {
  display: none;
}

.fancybox-show-infobar .fancybox-infobar {
  display: inline-block;
  pointer-events: all;
}

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: all;
}

.fancybox-show-buttons .fancybox-buttons {
  display: block;
}

.fancybox-slider-wrap {
  overflow: hidden;
}

.fancybox-slider-wrap,
.fancybox-slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 99993;
  backface-visibility: hidden;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0 44px;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide--image {
  overflow: hidden;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-content {
  display: inline-block;
  position: relative;
  margin: 44px auto;
  padding: 0;
  border: 0;
  width: 80%;
  height: calc(100% - 88px);
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  white-space: normal;
  outline: none;
  font-size: 16px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
  background: transparent;
}

.fancybox-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  background: transparent;
  background-size: 100% 100%;
}

.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.fancybox-controls--canzoomIn .fancybox-placeholder {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.fancybox-controls--canGrab .fancybox-placeholder {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-controls--isGrabbing .fancybox-placeholder {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-error {
  position: absolute;
  margin: 0;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 380px;
  max-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fancybox-close-small {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  font: 26px/1 Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: #fff;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  cursor: pointer;
  background: #252525;
  transition: background 0.2s;
  box-sizing: border-box;
  z-index: 2;
  user-select: none;
}

.fancybox-close-small:focus {
  outline: 1px dotted #888;
}

.fancybox-slide--video .fancybox-close-small {
  top: -36px;
  right: -36px;
  background: transparent;
}

.fancybox-close-small:hover {
  color: #555;
  background: #eee;
}

/* Caption */
.fancybox-caption-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0 30px;
  z-index: 99998;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none;
}

.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button {
  pointer-events: all;
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: transparent;
  color: #fff;
  box-sizing: border-box;
  vertical-align: top;
  outline: none;
}

.fancybox-button--disabled {
  cursor: default;
}

.fancybox-infobar__body, .fancybox-button {
  background: rgba(30, 30, 30, 0.6);
}

.fancybox-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.fancybox-button::before,
.fancybox-button::after {
  content: '';
  pointer-events: none;
  position: absolute;
  border-color: #fff;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.9;
  box-sizing: border-box;
  display: inline-block;
}

.fancybox-button--left::after {
  left: 20px;
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.fancybox-button--right::after {
  right: 20px;
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--left {
  border-bottom-left-radius: 5px;
}

.fancybox-button--right {
  border-bottom-right-radius: 5px;
}

.fancybox-button--close {
  float: right;
}

.fancybox-button--close::before, .fancybox-button--close::after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}

.fancybox-button--close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Loading spinner */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: fancybox-rotate .6s infinite linear;
  animation: fancybox-rotate .6s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-controls {
    text-align: left;
  }
  .fancybox-button--left,
  .fancybox-button--right,
  .fancybox-buttons button:not(.fancybox-button--close) {
    display: none !important;
  }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0;
  }
}

/* Fullscreen  */
.fancybox-button--fullscreen::before {
  width: 15px;
  height: 11px;
  left: 15px;
  top: 16px;
  border: 2px solid;
  background: none;
}

/* Slideshow button */
.fancybox-button--play::before {
  top: 16px;
  left: 18px;
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px;
  background: transparent;
}

.fancybox-button--pause::before {
  top: 16px;
  left: 18px;
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px 0 2px;
  background: transparent;
}

/* Thumbs */
.fancybox-button--thumbs span {
  font-size: 23px;
}

.fancybox-button--thumbs::before {
  top: 20px;
  left: 21px;
  width: 3px;
  height: 3px;
  box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, 0 0 0 32px inset, -4px 0 0, 4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0;
}

.fancybox-container--thumbs .fancybox-controls,
.fancybox-container--thumbs .fancybox-slider-wrap,
.fancybox-container--thumbs .fancybox-caption-wrap {
  right: 220px;
}

.fancybox-thumbs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 220px;
  margin: 0;
  padding: 5px 5px 0 0;
  background: #fff;
  z-index: 99993;
  word-break: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
}

.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  max-width: 50%;
  padding: 0;
  margin: 0;
  width: 105px;
  height: 75px;
  position: relative;
  cursor: pointer;
  outline: none;
  border: 5px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

li.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  max-height: none;
  user-select: none;
}

.fancybox-thumbs > ul > li:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    display: none !important;
  }
  .fancybox-container--thumbs .fancybox-controls,
  .fancybox-container--thumbs .fancybox-slider-wrap,
  .fancybox-container--thumbs .fancybox-caption-wrap {
    right: 0;
  }
}

.hamburger {
  height: 7.8rem;
  float: right;
  display: none;
}

@media screen and (max-width: 1208px) {
  .hamburger {
    display: block;
  }
}

.hamburger-label {
  float: left;
  padding-right: 1.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #808080;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding-top: 1rem;
}

.mm-menu, .mm-panels, .mm-panels > .mm-panel {
  margin: 0;
  left: 0;
  right: 0;
  z-index: 0;
  box-sizing: border-box;
}

.mm-listview a, .mm-listview a:hover, .mm-navbar a, .mm-navbar a:hover {
  text-decoration: none;
}

.mm-listview .mm-next:before, .mm-listview > li:not(.mm-divider):after, .mm-next:after, .mm-prev:before {
  content: '';
  bottom: 0;
}

.mm-hidden {
  display: none !important;
}

.mm-menu, .mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-btn, .mm-menu, .mm-navbar, .mm-next:after, .mm-panels, .mm-panels > .mm-panel, .mm-prev:before {
  position: absolute;
  top: 0;
}

.mm-menu {
  padding: 0;
  bottom: 0;
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  bottom: 0;
}

.mm-btn, .mm-panel.mm-highest {
  z-index: 1;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panel.mm-noanimation {
  -webkit-transition: none !important;
  transition: none !important;
}

.mm-panel.mm-noanimation.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  content: '';
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  box-sizing: border-box;
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-next:after, .mm-prev:before {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
}

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar .mm-btn:first-child {
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a, .mm-listview > li > span {
  color: inherit;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  right: 0;
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px;
}

.mm-menu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-divider, .mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
}

html.mm-blocking, html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

.mm-menu.mm-offcanvas.mm-opened {
  display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
  z-index: 10;
}

html.mm-opening .mm-menu ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

.mm-menu .mm-listview.mm-border-none > li:after, .mm-menu .mm-listview > li.mm-border-none:after, .mm-menu.mm-border-none .mm-listview > li:after {
  content: none;
}

.mm-menu .mm-listview.mm-border-full > li:after, .mm-menu .mm-listview > li.mm-border-full:after, .mm-menu.mm-border-full .mm-listview > li:after {
  left: 0 !important;
}

.mm-menu .mm-listview.mm-border-offset > li:after, .mm-menu .mm-listview > li.mm-border-offset:after, .mm-menu.mm-border-offset .mm-listview > li:after {
  right: 20px;
}

.mm-menu.mm-fx-menu-zoom {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

html.mm-opening .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
}

html.mm-right.mm-opening .mm-menu.mm-fx-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu.mm-fx-menu-slide {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

html.mm-opening .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

html.mm-right.mm-opening .mm-menu.mm-fx-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-fx-menu-fade {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

html.mm-opening .mm-menu.mm-fx-menu-fade {
  opacity: 1;
}

.mm-menu .mm-fx-panels-none.mm-panel, .mm-menu.mm-fx-panels-none .mm-panel {
  -webkit-transition-property: none;
  transition-property: none;
}

.mm-menu .mm-fx-panels-none.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-none .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu .mm-fx-panels-zoom.mm-panel, .mm-menu.mm-fx-panels-zoom .mm-panel {
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
}

.mm-menu .mm-fx-panels-zoom.mm-panel.mm-opened, .mm-menu.mm-fx-panels-zoom .mm-panel.mm-opened {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu .mm-fx-panels-zoom.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-zoom .mm-panel.mm-subopened {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
}

.mm-menu .mm-fx-panels-slide-0.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-0 .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu .mm-fx-panels-slide-100.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-100 .mm-panel.mm-subopened {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu .mm-fx-panels-slide-up.mm-panel, .mm-menu.mm-fx-panels-slide-up .mm-panel {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.mm-menu .mm-fx-panels-slide-up.mm-panel.mm-opened, .mm-menu .mm-fx-panels-slide-up.mm-panel.mm-subopened, .mm-menu.mm-fx-panels-slide-up .mm-panel.mm-opened, .mm-menu.mm-fx-panels-slide-up .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li {
  -webkit-transition: none .4s ease;
  transition: none .4s ease;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(1) {
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(2) {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(3) {
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(4) {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(5) {
  -webkit-transition-delay: 250ms;
  transition-delay: 250ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(6) {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(7) {
  -webkit-transition-delay: 350ms;
  transition-delay: 350ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(8) {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(9) {
  -webkit-transition-delay: 450ms;
  transition-delay: 450ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(10) {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(11) {
  -webkit-transition-delay: 550ms;
  transition-delay: 550ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(12) {
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(13) {
  -webkit-transition-delay: 650ms;
  transition-delay: 650ms;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(14) {
  -webkit-transition-delay: .7s;
  transition-delay: .7s;
}

.mm-menu[class*=mm-fx-listitems-] .mm-listview > li:nth-child(15) {
  -webkit-transition-delay: 750ms;
  transition-delay: 750ms;
}

.mm-menu.mm-fx-listitems-slide .mm-listview > li {
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
  opacity: 0;
}

html.mm-opening .mm-menu.mm-fx-listitems-slide .mm-panel.mm-opened .mm-listview > li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.mm-menu.mm-fx-listitems-fade .mm-listview > li {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 0;
}

html.mm-opening .mm-menu.mm-fx-listitems-fade .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
}

.mm-menu.mm-fx-listitems-drop .mm-listview > li {
  -webkit-transition-property: opacity,top;
  transition-property: opacity,top;
  opacity: 0;
  top: -25%;
}

html.mm-opening .mm-menu.mm-fx-listitems-drop .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
  top: 0;
}

.mm-menu.mm-offcanvas.mm-fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px;
}

html.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(10000px, 0);
    -ms-transform: translate(10000px, 0);
    transform: translate(10000px, 0);
    -webkit-transform: translate3d(10000px, 0, 0);
    transform: translate3d(10000px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-right.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-right.mm-opening .mm-menu.mm-fullscreen ~ .mm-slideout {
    -webkit-transform: translate(-10000px, 0);
    -ms-transform: translate(-10000px, 0);
    transform: translate(-10000px, 0);
    -webkit-transform: translate3d(-10000px, 0, 0);
    transform: translate3d(-10000px, 0, 0);
  }
}

.mm-menu.mm-offcanvas.mm-fullscreen.mm-bottom, .mm-menu.mm-offcanvas.mm-fullscreen.mm-top {
  height: 100%;
  min-height: 140px;
  max-height: 10000px;
}

html.mm-iconbar body {
  overflow-x: hidden;
}

html.mm-iconbar .mm-page {
  background: inherit;
  min-height: 100vh;
}

html.mm-iconbar .mm-slideout {
  box-sizing: border-box;
  padding-right: 60px;
  -webkit-transform: translate(60px, 0);
  -ms-transform: translate(60px, 0);
  transform: translate(60px, 0);
  -webkit-transform: translate3d(60px, 0, 0);
  transform: translate3d(60px, 0, 0);
}

.mm-menu.mm-iconbar {
  display: block;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify:after, .mm-menu .mm-panels > .mm-panel.mm-listview-justify:before, .mm-menu.mm-listview-justify .mm-panels > .mm-panel:after, .mm-menu.mm-listview-justify .mm-panels > .mm-panel:before {
  content: none;
  display: none;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  min-height: 40px;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li:not(.mm-divider), .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li > a:not(.mm-next), .mm-menu .mm-panels > .mm-panel.mm-listview-justify > .mm-listview > li > span, .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li > a:not(.mm-next), .mm-menu.mm-listview-justify .mm-panels > .mm-panel > .mm-listview > li > span {
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mm-listview-small .mm-listview > li > a:not(.mm-next), .mm-listview-small .mm-listview > li > span {
  padding: 7px 10px 7px 20px;
}

.mm-listview-small .mm-listview > li.mm-vertical > .mm-next, .mm-listview-small .mm-vertical > .mm-listview > li > .mm-next {
  height: 34px;
}

.mm-listview-large .mm-listview > li > a:not(.mm-next), .mm-listview-large .mm-listview > li > span {
  padding: 15px 10px 15px 20px;
}

.mm-listview-large .mm-listview > li.mm-vertical > .mm-next, .mm-listview-large .mm-vertical > .mm-listview > li > .mm-next {
  height: 50px;
}

.mm-listview-huge .mm-listview > li > a:not(.mm-next), .mm-listview-huge .mm-listview > li > span {
  padding: 20px 10px 20px 20px;
}

.mm-listview-huge .mm-listview > li.mm-vertical > .mm-next, .mm-listview-huge .mm-vertical > .mm-listview > li > .mm-next {
  height: 60px;
}

.mm-listview .mm-divider {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px;
}

.mm-listview .mm-spacer {
  padding-top: 40px;
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px;
}

.mm-listview .mm-inset {
  list-style: disc inside;
  padding: 0 10px 15px 40px;
  margin: 0;
}

.mm-listview .mm-inset > li {
  padding: 5px 0;
}

.mm-menu .mm-listview.mm-multiline > li > a, .mm-menu .mm-listview.mm-multiline > li > span, .mm-menu .mm-listview > li.mm-multiline > a, .mm-menu .mm-listview > li.mm-multiline > span, .mm-menu.mm-multiline .mm-listview > li > a, .mm-menu.mm-multiline .mm-listview > li > span {
  text-overflow: clip;
  white-space: normal;
}

.mm-menu.mm-opened[class*=mm-pagedim] ~ #mm-blocker {
  opacity: 0;
}

html.mm-opening .mm-menu.mm-opened[class*=mm-pagedim] ~ #mm-blocker {
  opacity: .3;
  -webkit-transition: opacity .4s ease .4s;
  transition: opacity .4s ease .4s;
}

.mm-menu.mm-opened.mm-pagedim ~ #mm-blocker {
  background: inherit;
}

.mm-menu.mm-opened.mm-pagedim-white ~ #mm-blocker {
  background: #fff;
}

.mm-menu.mm-opened.mm-pagedim-black ~ #mm-blocker {
  background: #000;
}

.mm-menu.mm-popup {
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
  opacity: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
  min-height: 140px;
  max-height: 880px;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.mm-menu.mm-popup.mm-opened ~ .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

.mm-menu.mm-popup.mm-opened ~ #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-opening .mm-menu.mm-popup {
  opacity: 1;
}

.mm-menu.mm-offcanvas.mm-right {
  left: auto;
}

html.mm-right.mm-opening .mm-menu ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-menu ~ .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-front #mm-blocker {
  z-index: 1;
}

html.mm-front .mm-menu.mm-offcanvas {
  z-index: 2;
}

.mm-menu.mm-offcanvas.mm-front, .mm-menu.mm-offcanvas.mm-next {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-front.mm-right, .mm-menu.mm-offcanvas.mm-next.mm-right {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-top {
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.mm-menu.mm-offcanvas.mm-bottom {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  top: auto;
}

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 80%;
  min-height: 140px;
  max-height: 880px;
}

html.mm-opening .mm-menu.mm-offcanvas.mm-front, html.mm-opening .mm-menu.mm-offcanvas.mm-next {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-shadow-page:after {
  content: "";
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 100;
}

.mm-menu.mm-shadow-page.mm-right:after {
  left: auto;
  right: 100%;
}

.mm-menu.mm-shadow-page.mm-front:after, .mm-menu.mm-shadow-page.mm-next:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-page:after, .mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-dark {
  background: #333;
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-navbar a, .mm-menu.mm-theme-dark .mm-navbar > *, .mm-menu.mm-theme-dark em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-btn:after, .mm-menu.mm-theme-dark .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview {
  border-color: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-dark .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-divider, .mm-menu.mm-theme-dark .mm-fixeddivider span, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu.mm-theme-dark label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-shadow-page.mm-theme-dark:after, .mm-menu.mm-shadow-panels.mm-theme-dark .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.mm-menu.mm-theme-dark .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-indexer a, .mm-menu.mm-theme-dark .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark label.mm-toggle {
  background: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark label.mm-toggle:before {
  background: #333;
}

.mm-menu.mm-theme-dark input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-white {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-keyboardfocus a:focus, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected:not(.mm-fullsubopen), .mm-menu.mm-theme-white .mm-divider, .mm-menu.mm-theme-white .mm-fixeddivider span, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-white .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-white .mm-listview > li.mm-selected > span, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-navbar a, .mm-menu.mm-theme-white .mm-navbar > *, .mm-menu.mm-theme-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-btn:after, .mm-menu.mm-theme-white .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  tap-highlight-color: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-shadow-page.mm-theme-white:after, .mm-menu.mm-shadow-panels.mm-theme-white .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mm-menu.mm-theme-white .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white .mm-indexer a, .mm-menu.mm-theme-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white label.mm-toggle:before {
  background: #fff;
}

.mm-menu.mm-theme-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-black {
  background: #000;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-navbar a, .mm-menu.mm-theme-black .mm-navbar > *, .mm-menu.mm-theme-black em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-btn:after, .mm-menu.mm-theme-black .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview {
  border-color: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
  tap-highlight-color: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-black .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-divider, .mm-menu.mm-theme-black .mm-fixeddivider span, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-keyboardfocus a:focus, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-shadow-page.mm-theme-black:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-panels.mm-theme-black .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: false;
}

.mm-menu.mm-theme-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-indexer a, .mm-menu.mm-theme-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-toggle:before {
  background: #000;
}

.mm-menu.mm-theme-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu .mm-tileview.mm-listview:after, .mm-menu.mm-tileview .mm-listview:after {
  content: '';
  display: block;
  clear: both;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next:after, .mm-menu .mm-tileview.mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-listview > li > .mm-next:after, .mm-menu.mm-tileview .mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-panel:after {
  content: none;
  display: none;
}

.mm-menu .mm-tileview.mm-listview > li, .mm-menu.mm-tileview .mm-listview > li {
  width: 50%;
  height: 0;
  padding: 50% 0 0;
  float: left;
  position: relative;
}

.mm-menu .mm-tileview.mm-listview > li:after, .mm-menu.mm-tileview .mm-listview > li:after {
  left: 0;
  top: 0;
  border-right-width: 1px;
  border-right-style: solid;
  z-index: -1;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xs, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xs {
  width: 12.5%;
  padding-top: 12.5%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-s, .mm-menu.mm-tileview .mm-listview > li.mm-tile-s {
  width: 25%;
  padding-top: 25%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-l, .mm-menu.mm-tileview .mm-listview > li.mm-tile-l {
  width: 75%;
  padding-top: 75%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xl, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xl {
  width: 100%;
  padding-top: 100%;
}

.mm-menu .mm-tileview.mm-listview > li > a, .mm-menu .mm-tileview.mm-listview > li > span, .mm-menu.mm-tileview .mm-listview > li > a, .mm-menu.mm-tileview .mm-listview > li > span {
  line-height: 1px;
  text-align: center;
  padding: 50% 10px 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next, .mm-menu.mm-tileview .mm-listview > li > .mm-next {
  width: auto;
}

.mm-menu.mm-tileview .mm-panel {
  padding-left: 0;
  padding-right: 0;
}

.mm-menu.mm-tileview .mm-listview {
  margin: 0;
}

html.mm-widescreen body {
  position: relative;
}

html.mm-widescreen #mm-blocker {
  display: none !important;
}

html.mm-widescreen .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  width: 70% !important;
  margin-left: 30% !important;
}

html.mm-widescreen .mm-page {
  background: inherit;
  box-sizing: border-box;
}

html.mm-widescreen.mm-blocking, html.mm-widescreen.mm-blocking body {
  overflow: auto;
}

.mm-menu.mm-widescreen {
  border-right-width: 1px;
  border-right-style: solid;
  display: block !important;
  width: 30% !important;
  min-width: 0 !important;
  max-width: none !important;
  top: 0 !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 100 !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-menu.mm-widescreen.mm-pageshadow:after {
  content: none;
  display: none;
}

.mm-menu.mm-autoheight {
  -webkit-transition: none .4s ease;
  transition: none .4s ease;
  -webkit-transition-property: height,-webkit-transform;
  transition-property: height,-webkit-transform;
  transition-property: transform,height;
  transition-property: transform,height,-webkit-transform;
}

.mm-menu.mm-autoheight:not(.mm-offcanvas) {
  position: relative;
}

.mm-menu.mm-measureheight .mm-panel {
  display: block !important;
}

.mm-menu.mm-measureheight .mm-panels > .mm-panel {
  bottom: auto !important;
  height: auto !important;
}

.mm-columns {
  -webkit-transition-property: width;
  transition-property: width;
}

.mm-columns .mm-panels > .mm-panel {
  right: auto;
  -webkit-transition-property: width,-webkit-transform;
  transition-property: width,-webkit-transform;
  transition-property: width,transform;
  transition-property: width,transform,-webkit-transform;
}

.mm-columns .mm-panels > .mm-panel.mm-opened, .mm-columns .mm-panels > .mm-panel.mm-subopened {
  border-left: 1px solid;
  border-color: inherit;
  display: block !important;
}

.mm-columns .mm-panels > .mm-columns-0 {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-columns-0 .mm-panels > .mm-panel {
  z-index: 0;
}

.mm-columns-0 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-0 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-0 {
  width: 80%;
  min-width: 140px;
  max-width: 0;
}

html.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-0 ~ .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-1 {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-columns-1 .mm-panels > .mm-panel {
  z-index: 1;
  width: 100%;
}

.mm-columns-1 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-1 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-1 {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-1 ~ .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-2 {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-columns-2 .mm-panels > .mm-panel {
  z-index: 2;
  width: 50%;
}

.mm-columns-2 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-2 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-2 {
  width: 80%;
  min-width: 140px;
  max-width: 880px;
}

html.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(880px, 0);
    -ms-transform: translate(880px, 0);
    transform: translate(880px, 0);
    -webkit-transform: translate3d(880px, 0, 0);
    transform: translate3d(880px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-2 ~ .mm-slideout {
    -webkit-transform: translate(-880px, 0);
    -ms-transform: translate(-880px, 0);
    transform: translate(-880px, 0);
    -webkit-transform: translate3d(-880px, 0, 0);
    transform: translate3d(-880px, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-3 {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-columns-3 .mm-panels > .mm-panel {
  z-index: 3;
  width: 33.34%;
}

.mm-columns-3 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-3 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-3 {
  width: 80%;
  min-width: 140px;
  max-width: 1320px;
}

html.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(1320px, 0);
    -ms-transform: translate(1320px, 0);
    transform: translate(1320px, 0);
    -webkit-transform: translate3d(1320px, 0, 0);
    transform: translate3d(1320px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-3 ~ .mm-slideout {
    -webkit-transform: translate(-1320px, 0);
    -ms-transform: translate(-1320px, 0);
    transform: translate(-1320px, 0);
    -webkit-transform: translate3d(-1320px, 0, 0);
    transform: translate3d(-1320px, 0, 0);
  }
}

.mm-columns .mm-panels > .mm-columns-4 {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-columns-4 .mm-panels > .mm-panel {
  z-index: 4;
  width: 25%;
}

.mm-columns-4 .mm-panels > .mm-panel else {
  width: 100%;
}

.mm-columns-4 .mm-panels > .mm-panel:not(.mm-opened):not(.mm-subopened) {
  -webkit-transform: translate(500%, 0);
  -ms-transform: translate(500%, 0);
  transform: translate(500%, 0);
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-4 {
  width: 80%;
  min-width: 140px;
  max-width: 1760px;
}

html.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(1760px, 0);
    -ms-transform: translate(1760px, 0);
    transform: translate(1760px, 0);
    -webkit-transform: translate3d(1760px, 0, 0);
    transform: translate3d(1760px, 0, 0);
  }
}

html.mm-right.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-right.mm-opening .mm-menu.mm-columns-4 ~ .mm-slideout {
    -webkit-transform: translate(-1760px, 0);
    -ms-transform: translate(-1760px, 0);
    transform: translate(-1760px, 0);
    -webkit-transform: translate3d(-1760px, 0, 0);
    transform: translate3d(-1760px, 0, 0);
  }
}

.mm-columns.mm-offcanvas.mm-bottom, .mm-columns.mm-offcanvas.mm-top {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

html.mm-opening .mm-columns.mm-offcanvas.mm-front, html.mm-opening .mm-columns.mm-offcanvas.mm-next {
  -webkit-transition-property: width,min-width,max-width,-webkit-transform;
  transition-property: width,min-width,max-width,-webkit-transform;
  transition-property: width,min-width,max-width,transform;
  transition-property: width,min-width,max-width,transform,-webkit-transform;
}

em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 45px;
  top: 50%;
}

em.mm-counter + a.mm-next {
  width: 90px;
}

em.mm-counter + a.mm-next + a, em.mm-counter + a.mm-next + span {
  margin-right: 90px;
}

em.mm-counter + a.mm-fullsubopen {
  padding-left: 0;
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a, .mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span, em.mm-counter + a.mm-fullsubopen + a, em.mm-counter + a.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-vertical > .mm-counter {
  top: 12px;
  margin-top: 0;
}

.mm-vertical.mm-spacer > .mm-counter {
  margin-top: 40px;
}

.mm-nosubresults > .mm-counter {
  display: none;
}

.mm-menu em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-divider > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  line-height: 25px;
}

.mm-divider.mm-opened a.mm-next:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-collapsed:not(.mm-uncollapsed) {
  display: none;
}

.mm-fixeddivider {
  background: inherit;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-fixeddivider:after {
  content: none !important;
  display: none !important;
}

.mm-hasdividers .mm-fixeddivider {
  display: block;
}

.mm-menu .mm-fixeddivider span {
  background: rgba(0, 0, 0, 0.05);
}

html.mm-opened.mm-dragging .mm-menu, html.mm-opened.mm-dragging .mm-slideout {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.mm-menu.mm-dropdown {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
}

html.mm-dropdown .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-dropdown #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-dropdown .mm-menu {
  z-index: 2;
}

html.mm-dropdown.mm-opened:not(.mm-opening) .mm-menu {
  display: none;
}

.mm-menu.mm-tip:before {
  content: '';
  background: inherit;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-menu.mm-tipleft:before {
  left: 22px;
}

.mm-menu.mm-tipright:before {
  right: 22px;
}

.mm-menu.mm-tiptop:before {
  top: -8px;
}

.mm-menu.mm-tipbottom:before {
  bottom: -8px;
}

.mm-iconpanel .mm-panels > .mm-panel {
  -webkit-transition-property: left,-webkit-transform;
  transition-property: left,-webkit-transform;
  transition-property: transform,left;
  transition-property: transform,left,-webkit-transform;
}

.mm-iconpanel .mm-panels > .mm-panel.mm-opened, .mm-iconpanel .mm-panels > .mm-panel.mm-subopened {
  border-left: 1px solid;
  border-color: inherit;
  display: block !important;
}

.mm-iconpanel .mm-panels > .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  left: 0;
}

.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  left: 40px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  left: 80px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  left: 120px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  left: 160px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  left: 200px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  left: 240px;
}

.mm-subblocker {
  background: inherit;
  opacity: 0;
  display: block;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.mm-subopened .mm-subblocker {
  opacity: .6;
  bottom: -100000px;
}

.mm-keyboardfocus a:focus {
  outline: 0;
}

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(255, 255, 255, 0.5);
}

.mm-navbars-bottom, .mm-navbars-top {
  background: inherit;
  border-color: inherit;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}

.mm-navbars-bottom > .mm-navbar, .mm-navbars-top > .mm-navbar {
  border: none;
  padding: 0;
  position: relative;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-navbars-top {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 0;
}

.mm-navbars-bottom {
  border-top-style: solid;
  border-top-width: 1px;
  bottom: 0;
}

.mm-navbar.mm-hasbtns {
  padding: 0 40px;
}

.mm-navbar[class*=mm-navbar-content-] > * {
  box-sizing: border-box;
  display: block;
  float: left;
}

.mm-navbar .mm-breadcrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0 0 0 17px;
}

.mm-navbar .mm-breadcrumbs > * {
  display: inline-block;
  padding: 10px 3px;
}

.mm-navbar .mm-breadcrumbs > a {
  text-decoration: underline;
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
  margin-left: -40px;
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
}

.mm-hasnavbar-top-1 .mm-panels {
  top: 40px;
}

.mm-hasnavbar-top-2 .mm-panels {
  top: 80px;
}

.mm-hasnavbar-top-3 .mm-panels {
  top: 120px;
}

.mm-hasnavbar-top-4 .mm-panels {
  top: 160px;
}

.mm-hasnavbar-bottom-1 .mm-panels {
  bottom: 40px;
}

.mm-hasnavbar-bottom-2 .mm-panels {
  bottom: 80px;
}

.mm-hasnavbar-bottom-3 .mm-panels {
  bottom: 120px;
}

.mm-hasnavbar-bottom-4 .mm-panels {
  bottom: 160px;
}

.mm-navbar-size-2 {
  height: 80px;
}

.mm-navbar-size-3 {
  height: 120px;
}

.mm-navbar-size-4 {
  height: 160px;
}

.mm-navbar-content-2 > * {
  width: 50%;
}

.mm-navbar-content-3 > * {
  width: 33.33%;
}

.mm-navbar-content-4 > * {
  width: 25%;
}

.mm-navbar-content-5 > * {
  width: 20%;
}

.mm-navbar-content-6 > * {
  width: 16.67%;
}

.mm-menu.mm-rtl {
  direction: rtl;
}

.mm-menu.mm-rtl.mm-offcanvas {
  right: auto;
}

.mm-menu.mm-rtl .mm-panel:not(.mm-opened) {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-rtl .mm-panel.mm-subopened {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:first-child {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:last-child {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-navbar .mm-prev:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-menu.mm-rtl .mm-listview > li:not(.mm-divider)::after {
  left: 0;
  right: 20px;
}

.mm-menu.mm-rtl .mm-listview > li > a:not(.mm-next), .mm-menu.mm-rtl .mm-listview > li > span:not(.mm-next) {
  padding-left: 10px;
  padding-right: 20px !important;
  margin-right: 0 !important;
}

.mm-menu.mm-rtl .mm-listview .mm-next {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:before {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-listview .mm-next + a, .mm-menu.mm-rtl .mm-listview .mm-next + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter {
  left: 45px;
  right: auto;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + span {
  padding-left: 90px;
}

.mm-menu.mm-rtl label.mm-check, .mm-menu.mm-rtl label.mm-toggle {
  left: 20px;
  right: auto !important;
}

.mm-menu.mm-rtl label.mm-toggle + a, .mm-menu.mm-rtl label.mm-toggle + span {
  padding-left: 80px;
}

.mm-menu.mm-rtl label.mm-check + a, .mm-menu.mm-rtl label.mm-check + span {
  padding-left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check, .mm-menu.mm-rtl a.mm-next + label.mm-toggle {
  left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  padding-left: 70px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle {
  left: 100px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + span, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-] {
  left: 0;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-].mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel {
  -webkit-transition-property: right,-webkit-transform;
  transition-property: right,-webkit-transform;
  transition-property: transform,right;
  transition-property: transform,right,-webkit-transform;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  right: 0;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  right: 40px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  right: 80px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  right: 120px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  right: 160px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  right: 200px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  right: 240px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-opened {
  border-left: none;
  border-right: 1px solid;
  border-color: inherit;
}

.mm-search, .mm-search input {
  box-sizing: border-box;
}

.mm-search {
  height: 40px;
  padding: 7px 10px 0;
  position: relative;
}

.mm-search input {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  font: inherit;
  font-size: 14px;
  line-height: 26px;
  display: block;
  width: 100%;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  margin: 0;
  padding: 0 10px;
}

.mm-search input::-ms-clear {
  display: none;
}

.mm-search .mm-clear, .mm-search .mm-next {
  right: 0;
}

.mm-panel > .mm-search {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mm-panel.mm-hassearch {
  padding-top: 40px;
}

.mm-panel.mm-hassearch.mm-hasnavbar {
  padding-top: 80px;
}

.mm-panel.mm-hassearch.mm-hasnavbar > .mm-search {
  top: 40px;
}

.mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  padding: 40px 0;
}

.mm-noresults .mm-indexer {
  display: none !important;
}

li.mm-nosubresults > a.mm-next {
  display: none;
}

li.mm-nosubresults > a.mm-next + a, li.mm-nosubresults > a.mm-next + span {
  padding-right: 10px;
}

.mm-menu .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-indexer {
  background: inherit;
  text-align: center;
  font-size: 12px;
  box-sizing: border-box;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100px;
  z-index: 15;
  -webkit-transition: right .4s ease;
  transition: right .4s ease;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-indexer a {
  text-decoration: none;
  display: block;
  height: 3.85%;
}

.mm-indexer ~ .mm-panel.mm-hasindexer {
  padding-right: 40px;
}

.mm-hasindexer .mm-indexer {
  right: 0;
}

.mm-hasindexer .mm-fixeddivider {
  right: 20px;
}

.mm-menu .mm-indexer a {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a:not(.mm-fullsubopen) {
  -webkit-transition: background .4s ease;
  transition: background .4s ease;
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.5);
}

input.mm-check, input.mm-toggle {
  position: absolute;
  left: -10000px;
}

label.mm-check, label.mm-toggle {
  margin: 0;
  position: absolute;
  top: 50%;
  z-index: 2;
  right: 20px;
}

label.mm-check:before, label.mm-toggle:before {
  content: '';
  display: block;
}

label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin-top: -15px;
}

label.mm-toggle:before {
  border-radius: 30px;
  width: 28px;
  height: 28px;
  margin: 1px;
}

input.mm-toggle:checked ~ label.mm-toggle:before {
  float: right;
}

label.mm-check {
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

label.mm-check:before {
  border-left: 3px solid;
  border-bottom: 3px solid;
  width: 40%;
  height: 20%;
  margin: 25% 0 0 20%;
  opacity: .1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input.mm-check:checked ~ label.mm-check:before {
  opacity: 1;
}

li.mm-vertical label.mm-check, li.mm-vertical label.mm-toggle {
  bottom: auto;
  margin-top: 0;
  top: 5px;
}

label.mm-toggle + a, label.mm-toggle + span {
  padding-right: 80px;
}

label.mm-check + a, label.mm-check + span {
  padding-right: 60px;
}

a.mm-next + label.mm-check, a.mm-next + label.mm-toggle {
  right: 60px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span, a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  margin-right: 50px;
}

a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  padding-right: 70px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span {
  padding-right: 50px;
}

em.mm-counter + a.mm-next + label.mm-check, em.mm-counter + a.mm-next + label.mm-toggle {
  right: 100px;
}

em.mm-counter + a.mm-next + label.mm-check + a, em.mm-counter + a.mm-next + label.mm-check + span, em.mm-counter + a.mm-next + label.mm-toggle + a, em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-right: 90px;
}

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu label.mm-toggle:before {
  background: #f3f3f3;
}

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.75);
}

html.mm-right.mm-opened .mm-menu ~ .mm-page.mm-slideout {
  z-index: 5;
}

html.mm-right.mm-opening .mm-menu ~ .mm-page.mm-slideout {
  z-index: 5;
}

html.mm-opening .mm-menu.mm-opened[class*=mm-pagedim] ~ #mm-blocker {
  z-index: 30;
}

.mm-menu {
  color: #717070;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.3rem;
  text-transform: uppercase;
  background-color: #ffffff;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
}

.mm-listview > li:not(.mm-divider):after {
  border-color: #ffffff;
}

.mm-listview > li:not(.mm-divider):before {
  content: "";
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  right: 0;
  left: 20px;
  bottom: 0;
  width: 0;
  border-color: #7bbc00;
  transition: all 0.4s ease-in-out;
  z-index: 2;
}

.mm-listview .mm-next:before {
  content: none;
}

.mm-listview > li:not(.mm-divider):hover:before {
  width: 100%;
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: #717070;
}

.mm-menu .mm-listview > li .mm-next:after {
  color: #7bbc00;
}

.headroom,
.main-navigation {
  will-change: transform;
  transition: all 150ms ease-out;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--pinned + .main-navigation {
  transform: translateY(0%);
}

.headroom--unpinned {
  margin-top: -5rem;
}

.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  cursor: pointer;
  width: 22px;
  height: 33px;
  z-index: 2;
  box-shadow: inset 0 0 0 3px #fff;
  border-radius: 25px;
  position: fixed;
  bottom: calc(45vh + 4rem);
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-height: 1333px) {
  .icon-scroll {
    bottom: 4rem;
    position: absolute;
  }
}

.icon-scroll--video {
  bottom: 4rem;
  position: fixed;
}

@media screen and (max-height: 600px) {
  .icon-scroll--video {
    position: fixed;
  }
}

@media screen and (max-height: 880px) {
  .icon-scroll--video {
    bottom: 4rem;
    position: absolute;
  }
}

.icon-scroll:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #fff;
  margin-left: -2px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(15px);
  }
}

.accordeon {
  width: 100%;
  height: 70rem;
  overflow: hidden;
  margin-top: 12.5rem;
}

@media screen and (max-width: 1080px) {
  .accordeon {
    margin-top: 7rem;
  }
}

.accordeon__list {
  width: 100%;
  display: table;
  table-layout: fixed;
  margin: 0;
  padding: 0;
}

.accordeon__list:hover .accordeon__list-item:hover {
  width: 60%;
}

.accordeon__list:hover .accordeon__list-item:hover:before {
  opacity: 1;
}

.accordeon__list:hover .accordeon__list-item:hover:before {
  background-color: rgba(0, 0, 0, 0.7);
}

.accordeon__list-item {
  border-right: 0.3rem solid #f0f0f0;
  background-size: cover;
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  width: 16.666%;
  height: 70rem;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 500ms ease;
}

.accordeon__list-item:last-child {
  border-right: none;
}

.accordeon__list-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.accordeon__list-item:hover .accordeon__teaser {
  opacity: 0;
}

.accordeon__list-item:hover .accordeon__full {
  opacity: 1;
  transition: opacity .4s ease-out 0.4s , transform .35s ease-out 0.35s;
  transform: translateY(0);
}

.accordeon__content {
  height: 70rem;
  position: relative;
  overflow: hidden;
}

.accordeon__teaser {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 18rem);
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.accordeon__title--teaser {
  font-family: "Century Gothic Bold";
  font-size: 1.9rem;
  line-height: 1.6;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .accordeon__title--teaser {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1384px) {
  .accordeon__title--teaser {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1289px) {
  .accordeon__title--teaser {
    font-size: 1.3rem;
  }
}

.accordeon__image {
  margin-bottom: 2rem;
}

.accordeon__image img {
  margin: 0 auto;
  width: auto;
  height: 5.5rem;
}

@media screen and (max-width: 1384px) {
  .accordeon__image img {
    height: 4.5rem;
  }
}

@media screen and (max-width: 1289px) {
  .accordeon__image img {
    height: 4rem;
  }
}

.accordeon__image--full {
  float: left;
  margin-left: -9%;
}

.accordeon__image--full img {
  width: 3.5rem;
  margin-top: 0.2rem;
  height: auto;
}

.accordeon__full {
  padding: 10% 10%;
  color: #ffffff;
  transition: all .2s ease-out 0s;
  opacity: 0;
  transform: translateY(20px);
}

.accordeon__title--full {
  font-family: "Century Gothic Bold";
  font-size: 2.6rem;
  line-height: 1.45;
  display: block;
  text-transform: uppercase;
  width: 100%;
}

.accordeon__title--full:after {
  display: block;
  content: "";
  height: 0.3rem;
  width: 3.9rem;
  background-color: #ffffff;
  margin-top: 1rem;
}

.accordeon__text p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 1.7;
  margin-top: 3.5rem;
}

.accordeon__blocks {
  margin-top: 3.5rem;
}

.accordeon__blocks .paragraphs-items {
  display: flex;
  flex-wrap: wrap;
}

.accordeon-block {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
  background-color: #1c1c1c;
  transition: all 0.3s ease-in-out;
}

.accordeon-block:last-child {
  margin-right: 0;
}

.accordeon-block:nth-child(2n) {
  margin-right: 0;
}

.accordeon-block:nth-child(2n+1) {
  clear: left;
}

.accordeon-block:hover {
  background-color: #42680d;
}

.accordeon-block:nth-child(n+3) {
  margin-top: 2rem;
}

.accordeon-block__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 1.7rem;
  padding-top: 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.29);
  color: #ffffff;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.accordeon-block__text {
  font-size: 1.3rem;
  color: #ffffff;
  line-height: 2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  max-height: 6rem;
  /* Limit to 3 lines */
  overflow: hidden;
}

.accordeon_block__container {
  padding: 1.7rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 1080px) {
  .accordeon {
    height: auto;
  }
  .accordeon__teaser {
    top: 6rem;
    transform: translate(-50%, 0);
  }
  .accordeon__list-item {
    border-right: none;
    border-bottom: 0.3rem solid #f0f0f0;
    height: 20rem;
    transition: all 0.3s ease-in-out;
  }
  .accordeon__list-item:hover {
    height: 70rem;
  }
  .accordeon__list-item:hover .accordeon__content {
    height: 70rem;
  }
  .accordeon__teaser-title {
    font-size: 1.9rem;
  }
  .accordeon__image img {
    margin: 0 auto;
    width: auto;
    height: 5.5rem;
  }
  .accordeon__content {
    height: 20rem;
    transition: all 0.3s ease-in-out;
  }
  .accordeon__list .accordeon__list-item, .accordeon__list .accordeon__list-item:hover, .accordeon__list:hover .accordeon__list-item, .accordeon__list:hover .accordeon__list-item:hover {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}

.date {
  position: relative;
  border: 1px solid #d0d0d0;
  width: 6rem;
  height: 6rem;
  float: left;
}

.date__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.date__day {
  margin-top: -4px;
  font-size: 2.1rem;
  line-height: 1;
  font-family: "Century Gothic Bold";
  color: #272727;
  display: block;
}

.date__month {
  display: block;
  font-size: 1.2rem;
  font-family: "Century Gothic Bold";
  color: #272727;
  line-height: 1;
}

.a-block {
  background-size: cover;
  position: relative;
}

.a-block__cont {
  width: 100%;
  min-height: 61.1rem;
  background-size: cover;
}

.a-block__cont > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.a-block__content {
  margin: 3rem 2.5rem 3rem 2.5rem;
  width: 100%;
  max-width: 51.2rem;
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 601px) {
  .a-block__content {
    margin: 0 auto;
    max-width: 100%;
  }
}

.a-block__text {
  background-color: #ffffff;
  padding: 3.5rem 4.5rem 2.8rem 3.5rem;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.45;
  color: #1b1b1b;
}

.a-block__button {
  margin-top: 2.1rem;
}

.a-block__intro {
  margin-top: 1rem;
}

.a-block__logo {
  max-width: 12rem;
}

.a-block__bottom {
  padding: 2rem 4.5rem;
  background-color: #272727;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #ffffff;
  font-size: 1.9rem;
}

.a-block__icon {
  width: 2.9rem;
  margin-right: 1.4rem;
  height: 3rem;
  float: left;
  margin-top: -0.8rem;
  stroke: #7bbc00;
}

.view-display-id-reference_teaser_home_attachment .slick-track {
  display: flex;
}

.view-display-id-reference_teaser_home_attachment .slick-list {
  overflow: visible;
}

.logo {
  display: flex !important;
  height: auto;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  position: relative;
}

.logo:hover:before {
  transform: translate(-50%, 0);
}

.logo.slick-current:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000000;
  transition: all 0.1s ease-in-out;
}

.logo.slick-current:before {
  transform: translate(-50%, 0);
}

.logo:before {
  content: "";
  width: 10.5rem;
  z-index: -1;
  height: 7rem;
  transform: translate(-50%, 100%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' %3E%3Cpath fill-rule='evenodd'  fill='rgb(255, 255, 255)' d='M43.000,0.677 L85.507,31.185 L43.000,61.693 L0.493,31.185 L43.000,0.677 Z'/%3E%3C/svg%3E");
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
  position: absolute;
  top: -2rem;
  left: 50%;
}

.logo:first-child {
  border-left: none;
}

.logo:last-child {
  border-right: none;
}

.logo__img {
  padding: 2rem 20%;
  display: inline-block;
  width: 100%;
}

.b-blocks {
  background-color: #f0f0f0;
  padding: 6rem 0;
}

.b-blocks__content {
  margin-top: 3rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.5;
  color: #545454;
}

.b-blocks__content a {
  color: #7bbc00;
}

.b-blocks__content a:hover {
  border-bottom: 1px solid #7bbc00;
}

.b-block__container {
  position: relative;
}

.b-block {
  display: block;
  float: left;
  width: 26.66666666666667%;
  margin-right: 10%;
  position: relative;
  transition: all 0.25s ease-in-out;
  float: left;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
}

@media screen and (max-width: 1200px) {
  .b-block {
    width: 28.33333333333333%;
    margin-right: 7.5%;
  }
}

@media screen and (max-width: 1120px) {
  .b-block {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 31.7615653177%;
  }
  .b-block:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 960px) {
  .b-block {
    width: 100%;
    margin-top: 3rem;
  }
  .b-block:first-child {
    margin-top: 6rem;
  }
}

.b-block:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 10px 5px rgba(29, 29, 29, 0.05);
}

@media screen and (max-width: 960px) {
  .b-block:hover {
    box-shadow: none;
  }
}

.b-block:last-child {
  margin-right: 0;
}

.b-block:last-child:after {
  content: none;
}

.b-block:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 47 90' fill='%23b7b7b7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M47.31,43.82 L4.07,0.41 L3.99,0.35 C3.30886299,-0.116424256 2.41113701,-0.116424256 1.73,0.35 L1.22,0.69 C1.09611502,0.770668623 1.01527219,0.902956892 1,1.05 C0.985059096,1.1974949 1.03634114,1.34401502 1.14,1.45 L44.22,44.52 C44.368666,44.6434913 44.4546688,44.8267341 44.4546688,45.02 C44.4546688,45.2132659 44.368666,45.3965087 44.22,45.52 L1.15,88.42 C1.04353515,88.5232157 0.989537432,88.6690912 1.00313763,88.8167505 C1.01673783,88.9644098 1.09647284,89.0979678 1.22,89.18 L1.66,89.48 C1.90913904,89.7801304 2.2707682,89.9645613 2.66,89.99 L2.82,89.99 C3.29660215,89.9874319 3.75295741,89.7969844 4.09,89.46 L47.31,46.23 C47.6490811,45.9211216 47.842332,45.483674 47.842332,45.025 C47.842332,44.566326 47.6490811,44.1288784 47.31,43.82 Z'%3E%3C/path%3E%3C/svg%3E");
  position: absolute;
  top: 50%;
  right: calc(-20% - 1.2rem);
  transform: translateY(-50%);
  width: 2.4em;
  height: 4.6rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2.4rem;
}

@media screen and (max-width: 1200px) {
  .b-block:after {
    right: calc(-15% - 1.2rem);
  }
}

@media screen and (max-width: 1120px) {
  .b-block:after {
    content: none;
  }
}

@media screen and (max-width: 960px) {
  .b-block:after {
    content: "";
    position: relative;
    width: 4.6rem;
    margin: 3rem auto 0 auto;
    right: 0;
    top: 0;
    display: block;
    transform: rotate(90deg);
  }
}

.b-block:nth-child(1) .b-block__top {
  background-color: #afca0b;
}

.b-block:nth-child(2) .b-block__top {
  background-color: #74ad24;
}

.b-block:nth-child(3) .b-block__top {
  background-color: #42680d;
}

.b-block__cont {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.b-block__top {
  position: relative;
}

.b-block__title {
  font-family: "Century Gothic";
  font-size: 2rem;
  line-height: 1.2;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding: 2.5rem 0;
}

.b-block__icon {
  padding-top: 3.5rem;
}

.b-block__icon img {
  margin: 0 auto;
  width: auto;
  height: 5rem;
}

.b-block__number {
  font-family: "Century Gothic Bold";
  font-size: 9rem;
  color: #ffffff;
  position: absolute;
  bottom: -1.1rem;
  left: 0.5rem;
}

.b-block__bottom {
  background-color: #ffffff;
  text-align: center;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.b-block__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.7;
  color: #545454;
}

.b-block__button {
  margin-top: 2rem;
}

.b-block__button-wrapper {
  margin-top: auto;
}

.b-block--paragraph {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
}

.b-block--paragraph:last-child {
  margin-right: 0;
}

.b-block--paragraph:nth-child(3n) {
  margin-right: 0;
}

.b-block--paragraph:nth-child(3n+1) {
  clear: left;
}

.b-block--paragraph:after {
  display: none;
}

@media screen and (max-width: 961px) {
  .b-block--paragraph {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    clear: both;
    margin-top: 3rem;
  }
  .b-block--paragraph:last-child {
    margin-right: 0;
  }
}

.b-blocks--paragraph .b-blocks__blocks {
  display: flex;
  flex-wrap: wrap;
}

.b-block--paragraph {
  display: flex;
  flex-direction: column;
}

.b-block--paragraph:nth-child(1) .b-block__top--paragraph:after {
  content: "1";
  font-family: "Century Gothic Bold";
  font-size: 9rem;
  color: #ffffff;
  position: absolute;
  bottom: -1.1rem;
  left: 0.5rem;
}

.b-block--paragraph:nth-child(2) .b-block__top--paragraph:after {
  content: "2";
  font-family: "Century Gothic Bold";
  font-size: 9rem;
  color: #ffffff;
  position: absolute;
  bottom: -1.1rem;
  left: 0.5rem;
}

.b-block--paragraph:nth-child(3) .b-block__top--paragraph:after {
  content: "3";
  font-family: "Century Gothic Bold";
  font-size: 9rem;
  color: #ffffff;
  position: absolute;
  bottom: -1.1rem;
  left: 0.5rem;
}

.view-display-id-aanpak_block .view-content {
  display: flex;
  flex-wrap: wrap;
}

.c-blocks {
  padding: 6rem 0 6rem 0;
}

.c-blocks .view-content {
  display: flex;
  flex-wrap: wrap;
}

.c-blocks .view-content::after {
  clear: both;
  content: "";
  display: block;
}

.c-blocks .more-link {
  clear: both;
  display: block;
  margin-top: 3rem;
  padding-top: 2.5rem;
  border-top: 1px solid #cecece;
}

.c-blocks--full {
  padding-top: 0;
  margin-top: 0;
  min-height: 50rem;
}

.c-blocks__title {
  margin-bottom: 3rem;
}

.c-blocks__text {
  margin-bottom: 4rem;
}

.c-block {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
  display: flex;
  flex-direction: column;
}

.c-block:last-child {
  margin-right: 0;
}

.c-block:nth-child(3n) {
  margin-right: 0;
}

.c-block:nth-child(3n+1) {
  clear: left;
}

.c-block:nth-child(n+4) {
  margin-top: 3rem;
}

.c-block__cont {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 756px) {
  .c-block--para {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .c-block--para:last-child {
    margin-right: 0;
  }
  .c-block--para:nth-child(n+2) {
    margin-top: 3rem;
  }
}

.c-block__image {
  flex-shrink: 0;
}

.c-block__button {
  margin-top: auto;
}

.c-block__button .c-block__button {
  margin-top: 2.5rem;
}

.c-block__naam {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #7bbc00;
  line-height: 1.6;
  margin-top: 1.5rem;
  display: block;
}

.c-block__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #1b1b1b;
  font-size: 1.6rem;
  margin-top: 0.2rem;
  line-height: 1.2;
}

.c-block__text {
  margin-top: 2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  color: #1b1b1b;
  line-height: 1.6;
}

.c-block__text p {
  margin-bottom: 0 !important;
}

.c-block__view-container {
  margin-top: 4rem;
}

.c-block--full {
  padding: 1.5rem;
  padding-bottom: 2.5rem;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(29, 29, 29, 0.16);
  transition: all 0.25s ease-in-out;
}

@media screen and (max-width: 850px) {
  .c-block--full {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .c-block--full:last-child {
    margin-right: 0;
  }
  .c-block--full:nth-child(3n) {
    margin-right: 2.3576520234%;
  }
  .c-block--full:nth-child(3n+1) {
    clear: none;
  }
  .c-block--full:nth-child(2n) {
    margin-right: 0;
  }
  .c-block--full:nth-child(2n+1) {
    clear: left;
  }
  .c-block--full:nth-child(n+3) {
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 589px) {
  .c-block--full {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .c-block--full:last-child {
    margin-right: 0;
  }
  .c-block--full:nth-child(2n) {
    margin-right: 2.3576520234%;
  }
  .c-block--full:nth-child(2n+1) {
    clear: none;
  }
  .c-block--full:nth-child(n) {
    margin-right: 0;
  }
  .c-block--full:nth-child(n+1) {
    margin-top: 2.5rem;
  }
}

.c-block--full:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 10px 5px rgba(29, 29, 29, 0.05);
}

.d-blocks {
  margin-top: 5.5rem;
}

.d-blocks__inner {
  border: 5px solid #7bbc00;
  width: 100%;
  padding: 4rem 4rem 7rem 4rem;
}

@media screen and (max-width: 635px) {
  .d-blocks__inner {
    padding: 4rem;
  }
}

.d-block {
  text-align: center;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 29.6291142339%;
}

.d-block:last-child {
  margin-right: 0;
}

.d-block:nth-child(3n) {
  margin-right: 0;
}

.d-block:nth-child(3n+1) {
  clear: left;
}

.d-block:first-child {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 36.0264674854%;
}

.d-block:first-child:last-child {
  margin-right: 0;
}

@media screen and (max-width: 635px) {
  .d-block:first-child {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .d-block:first-child:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 635px) {
  .d-block {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    text-align: left;
  }
  .d-block:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 635px) {
  .d-block {
    margin-bottom: 2rem;
  }
  .d-block:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 378px) {
  .d-block {
    text-align: center;
  }
}

.d-block__date {
  display: block;
  max-width: 136rem;
  margin: 0 auto;
  color: #ffffff;
  position: relative;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.4rem;
  line-height: 1.2;
}

@media screen and (max-width: 378px) {
  .d-block__date {
    text-align: center;
  }
}

.d-block__date--top {
  margin-bottom: 1rem;
  display: none;
}

@media screen and (max-width: 1142px) {
  .d-block__date--top {
    display: block;
  }
}

.d-block__date--bottom {
  margin-top: 1rem;
}

@media screen and (max-width: 1142px) {
  .d-block__date--bottom {
    display: none;
  }
}

.d-block__icon {
  height: 7rem;
  width: 7rem;
  margin: 0 auto;
  stroke: #7bbc00;
}

@media screen and (max-width: 635px) {
  .d-block__icon {
    height: 5rem;
    width: 6rem;
  }
}

.d-block__logo {
  margin-bottom: 2rem;
}

@media screen and (max-width: 635px) {
  .d-block__logo {
    margin-right: 1rem;
    margin-bottom: 0;
    float: left;
  }
}

@media screen and (max-width: 378px) {
  .d-block__logo {
    display: none;
  }
}

.d-block__number {
  font-family: "Century Gothic Bold";
  font-size: 4.6rem;
  color: #272727;
}

@media screen and (max-width: 992px) {
  .d-block__number {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 850px) {
  .d-block__number {
    font-size: 2.8rem;
  }
}

.d-block__prefix,
.d-block__suffix {
  font-family: "Century Gothic Bold";
  font-size: 4.6rem;
  color: #272727;
}

@media screen and (max-width: 992px) {
  .d-block__prefix,
  .d-block__suffix {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 850px) {
  .d-block__prefix,
  .d-block__suffix {
    font-size: 2.8rem;
  }
}

.d-block__subtitle {
  font-family: "Century Gothic";
  font-size: 1.8rem;
  color: #272727;
  text-transform: uppercase;
  margin-top: 1.5rem;
}

@media screen and (max-width: 992px) {
  .d-block__subtitle {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 850px) {
  .d-block__subtitle {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 635px) {
  .d-block__subtitle {
    margin-top: 0.5rem;
  }
}

.d-blocks--home {
  margin-bottom: -6.7em;
  margin-top: 5.5em;
}

@media screen and (max-width: 1142px) {
  .d-blocks--home {
    margin-bottom: -4.2em;
  }
}

.d-blocks--home .d-block__icon {
  stroke: #ffffff;
  height: 7em;
  width: 7em;
}

.d-blocks--home .d-block__number {
  color: #ffffff;
}

.d-blocks--home .d-block__subtitle {
  color: #ffffff;
}

.d-blocks--home .d-block__prefix,
.d-blocks--home .d-block__suffix {
  color: #ffffff;
  font-size: 4.6em;
}

.d-blocks--home .d-blocks__inner {
  padding: 4em 4em 8em 4em;
  width: 100%;
  left: 0;
  max-width: 136em;
  border: 2px solid #c8c8c8;
  margin: 0 auto;
}

@media screen and (max-width: 609px) {
  .d-blocks--home .d-blocks__inner {
    padding: 4rem;
  }
}

.d-blocks--home .d-block__logo {
  margin-bottom: 2em;
}

.d-blocks--home .d-block__number {
  font-size: 4.6em;
}

.d-blocks--home .d-block__subtitle {
  font-size: 1.8em;
  margin-top: 0.8333333333em;
}

.d-blocks--home .d-block__date {
  font-size: 1.4em;
  max-width: 97.1428571429em;
}

.e-blocks__content {
  width: calc(100% + 5rem);
  position: relative;
  left: -2.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
}

.e-blocks {
  margin-bottom: 13rem;
  margin-top: 5.5rem;
}

.e-blocks__title {
  width: calc(100% + 5rem);
  position: relative;
  left: -2.5rem;
}

.e-block {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
}

.e-block:last-child {
  margin-right: 0;
}

.e-block:nth-child(3n) {
  margin-right: 0;
}

.e-block:nth-child(3n+1) {
  clear: left;
}

.e-block:nth-child(n+4) {
  margin-top: 2.3576520234%;
}

@media screen and (max-width: 806px) {
  .e-block {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .e-block:last-child {
    margin-right: 0;
  }
  .e-block:nth-child(3n) {
    margin-right: 2.3576520234%;
  }
  .e-block:nth-child(3n+1) {
    clear: none;
  }
  .e-block:nth-child(2n) {
    margin-right: 0;
  }
  .e-block:nth-child(2n+1) {
    clear: left;
  }
  .e-block:nth-child(n+3) {
    margin-top: 2.3576520234%;
  }
}

@media screen and (max-width: 628px) {
  .e-block {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-top: 2.3576520234%;
  }
  .e-block:last-child {
    margin-right: 0;
  }
  .e-block:nth-child(2n) {
    margin-right: 2.3576520234%;
  }
  .e-block:nth-child(2n+1) {
    clear: none;
  }
  .e-block:nth-child(n) {
    margin-right: 0;
  }
  .e-block:first-child {
    margin-top: 0;
  }
}

.e-block__cont {
  background-color: #272727;
  padding: 4.5rem 3rem;
  transition: all 0.25s ease-in-out;
  position: relative;
}

.e-block__cont:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.3);
  opacity: 0;
}

.e-block__cont:hover {
  transform: translateY(-5px);
}

.e-block__cont:hover:after {
  opacity: 1;
}

.e-block__icon {
  width: 4rem;
  height: 3.7rem;
  fill: #7bbc00;
  float: left;
}

.e-block__content {
  margin-left: 6rem;
}

.e-block__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 1.5rem;
  color: #ffffff;
  transition: all 0.25s ease-in-out;
  line-height: 1.35;
  margin-top: -0.2rem;
}

.e-block__link {
  display: block;
  margin-top: 1rem;
}

.e-blocks--paragraphs {
  margin-bottom: 0;
}

.e-blocks--paragraphs .e-blocks__content {
  width: 100%;
  left: 0;
}

.e-blocks--paragraphs .e-blocks__title {
  width: 100%;
  left: 0;
}

.f-blocks__content {
  display: flex;
  flex-wrap: wrap;
}

.f-blocks__content .f-block--teaser {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 18.1138783813%;
  margin-top: 0;
}

.f-blocks__content .f-block--teaser:last-child {
  margin-right: 0;
}

.f-blocks__content .f-block--teaser:nth-child(5n) {
  margin-right: 0;
}

.f-blocks__content .f-block--teaser:nth-child(5n+1) {
  clear: left;
}

.f-blocks__content .f-block--teaser:first-child {
  margin-top: 0;
}

.f-blocks__content .f-block--teaser:nth-child(n+6) {
  margin-top: 2.3576520234%;
}

@media screen and (max-width: 1272px) {
  .f-blocks__content .f-block--teaser {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 23.2317609825%;
  }
  .f-blocks__content .f-block--teaser:last-child {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:nth-child(5n) {
    margin-right: 2.3576520234%;
  }
  .f-blocks__content .f-block--teaser:nth-child(5n+1) {
    clear: none;
  }
  .f-blocks__content .f-block--teaser:nth-child(4n) {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:nth-child(4n+1) {
    clear: left;
  }
  .f-blocks__content .f-block--teaser:nth-child(n+5) {
    margin-top: 2.3576520234%;
  }
}

@media screen and (max-width: 1052px) {
  .f-blocks__content .f-block--teaser {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 31.7615653177%;
  }
  .f-blocks__content .f-block--teaser:last-child {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:nth-child(4n) {
    margin-right: 2.3576520234%;
  }
  .f-blocks__content .f-block--teaser:nth-child(4n+1) {
    clear: none;
  }
  .f-blocks__content .f-block--teaser:nth-child(3n) {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:nth-child(3n+1) {
    clear: left;
  }
  .f-blocks__content .f-block--teaser:nth-child(n+4) {
    margin-top: 2.3576520234%;
  }
}

@media screen and (max-width: 804px) {
  .f-blocks__content .f-block--teaser {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .f-blocks__content .f-block--teaser:last-child {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:nth-child(3n) {
    margin-right: 2.3576520234%;
  }
  .f-blocks__content .f-block--teaser:nth-child(3n+1) {
    clear: none;
  }
  .f-blocks__content .f-block--teaser:nth-child(2n) {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:nth-child(2n+1) {
    clear: left;
  }
  .f-blocks__content .f-block--teaser:nth-child(n+3) {
    margin-top: 2.3576520234%;
  }
}

@media screen and (max-width: 430px) {
  .f-blocks__content .f-block--teaser {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-top: 2.5rem;
  }
  .f-blocks__content .f-block--teaser:last-child {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:nth-child(2n) {
    margin-right: 2.3576520234%;
  }
  .f-blocks__content .f-block--teaser:nth-child(2n+1) {
    clear: none;
  }
  .f-blocks__content .f-block--teaser:nth-child(n) {
    margin-right: 0;
  }
  .f-blocks__content .f-block--teaser:first-child {
    margin-top: 0;
  }
}

.f-block__cont--teaser {
  background-color: #f7f7f7;
  padding: 3rem;
  margin-top: 1.5rem;
  display: block;
  border: 2px solid #f7f7f7;
  transition: all 0.25s ease-in-out;
}

.f-block__cont--teaser:hover {
  background-color: #f1fbe3;
  border: 2px solid #7bbc00;
}

.f-block__cont--teaser .f-block__title {
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: #1b1b1b;
  margin-bottom: 1.6rem;
  line-height: 1.4;
}

.f-block__cont--teaser .f-block__subtitle {
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.6;
  color: #1b1b1b;
}

.f-block--large {
  min-height: 0;
}

.f-block--large .f-block__title {
  font-size: 1.8rem;
}

.block-locale {
  float: left;
}

.block-locale .selectric-items li.highlighted {
  margin-top: 5.5rem;
}

.selectric .label {
  margin: 0 3rem 0 1.7rem;
  height: 4.9rem;
  line-height: 4.9rem;
  min-width: 15rem;
}

.selectric a {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  color: #717070;
  text-transform: uppercase;
}

.selectric .button {
  width: 2.7rem;
  height: 4.9rem;
  color: #717070;
}

.selectric {
  border: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.selectric-items {
  border: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.selectric-items a {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  color: #717070;
  text-transform: uppercase;
}

.selectric-items a:hover {
  color: #000000;
}

.view-filters {
  margin-bottom: 2rem;
}

.view-filters::after {
  clear: both;
  content: "";
  display: block;
}

.view-filters .selectric {
  border: 1px solid #e0e0e0;
}

.view-filters form {
  max-width: 25rem;
  float: right;
}

.view-filters .selectric-items li {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  color: #717070;
  text-transform: uppercase;
}

.view-filters .selectric-items li:hover {
  color: #000000;
}

.view-filters .selectric .label {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  color: #717070;
  text-transform: uppercase;
}

.top-menu {
  float: right;
}

@media screen and (max-width: 1126px) {
  .top-menu {
    display: none;
  }
}

.top-menu .menu li {
  float: left;
}

.top-menu .menu a {
  font-size: 1.1rem;
  font-family: "Century Gothic";
  color: #717070;
  text-transform: uppercase;
  padding: 2rem 1.8rem 1.5rem 1.8rem;
  transition: all 0.25s ease-in-out;
  display: block;
}

.top-menu .menu a:hover, .top-menu .menu a.active {
  color: #7bbc00;
}

.navigation {
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff;
}

.main-navigation {
  background-color: #ffffff;
}

.hide-link {
  display: none !important;
}

.main-menu {
  float: right;
}

@media screen and (max-width: 1208px) {
  .main-menu {
    display: none;
  }
}

.main-menu > ul > li {
  position: relative;
  float: left;
  padding: 0 1.8rem;
}

.main-menu > ul > li:hover > a, .main-menu > ul > li:hover > span {
  color: #7bbc00;
}

.main-menu > ul > li:hover > ul {
  visibility: visible;
  opacity: 1;
}

.main-menu > ul > li:last-child {
  padding-right: 0;
}

.main-menu > ul > li:first-child {
  padding-left: 0;
}

.main-menu > ul > li > a, .main-menu > ul > li > span {
  font-size: 1.4rem;
  font-family: "Century Gothic";
  color: #2f2f2f;
  display: block;
  text-transform: uppercase;
  padding: 3.2rem 0;
  position: relative;
  overflow: hidden;
}

.main-menu > ul > li > a.active, .main-menu > ul > li > span.active {
  font-family: "Century Gothic Bold";
  color: #7bbc00;
}

@media screen and (max-width: 1322px) {
  .main-menu > ul > li > a, .main-menu > ul > li > span {
    font-size: 1.2rem;
  }
}

.main-menu > ul > li > ul {
  position: absolute;
  background-color: #ffffff;
  padding: 3.5rem 4rem;
  min-width: 70rem;
  box-shadow: 0px 3px 4px 0px rgba(29, 29, 29, 0.27);
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out;
}

.main-menu > ul > li > ul:after {
  content: "";
  position: absolute;
  bottom: -1.3rem;
  left: 0;
  width: 100%;
  height: 1.3rem;
  background: linear-gradient(270deg, #7bbc00, #609515);
  background-size: 400% 400%;
  animation: menu 10s ease infinite;
}

.main-menu > ul > li > ul > li {
  width: calc(50% - 3rem);
  border-right: 1px solid #e2e2e2;
  float: left;
  padding-bottom: 2rem;
  margin: 0 1.5rem;
}

.main-menu > ul > li > ul > li:nth-child(2n) {
  margin-right: 0;
  border-right: none;
  width: 50%;
}

.main-menu > ul > li > ul > li:nth-child(2n+1) {
  clear: both;
  margin-left: 0;
}

.main-menu > ul > li > ul > li:last-child {
  padding-bottom: 0;
}

.main-menu > ul > li > ul > li:first-child:last-child {
  width: 100%;
  margin: 0;
  border-right: none;
}

.main-menu > ul > li > ul > li > a, .main-menu > ul > li > ul > li > span {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #1b1b1b;
  line-height: 1.6;
  padding-bottom: 2rem;
  display: block;
  padding-right: 2rem;
}

@media screen and (max-width: 1322px) {
  .main-menu > ul > li > ul > li > a, .main-menu > ul > li > ul > li > span {
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }
}

.main-menu > ul > li > ul > li > ul > li > a, .main-menu > ul > li > ul > li > ul > li > span {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.6;
  color: #1b1b1b;
  padding-left: 2rem;
  position: relative;
  display: block;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1322px) {
  .main-menu > ul > li > ul > li > ul > li > a, .main-menu > ul > li > ul > li > ul > li > span {
    font-size: 1.2rem;
  }
}

.main-menu > ul > li > ul > li > ul > li > a:after, .main-menu > ul > li > ul > li > ul > li > span:after {
  content: "";
  position: absolute;
  width: 2.5rem;
  left: 0;
  top: 0;
  background-image: url("data:image/svg+xml,%3Csvg fill='%237bbc00' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  height: 100%;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: 0 0.8rem;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1322px) {
  .main-menu > ul > li > ul > li > ul > li > a:after, .main-menu > ul > li > ul > li > ul > li > span:after {
    background-size: 0.8rem;
  }
}

.main-menu > ul > li > ul > li > ul > li > a:hover, .main-menu > ul > li > ul > li > ul > li > span:hover {
  color: #7bbc00;
}

.main-menu > ul > li > ul > li > ul > li > a:hover:after, .main-menu > ul > li > ul > li > ul > li > span:hover:after {
  transform: translateX(0.4rem);
}

/* Footer menu */
.footer-menu {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 65.8807826589%;
}

.footer-menu:last-child {
  margin-right: 0;
}

@media screen and (max-width: 742px) {
  .footer-menu {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .footer-menu:last-child {
    margin-right: 0;
  }
}

.footer-menu li::after {
  clear: both;
  content: "";
  display: block;
}

.footer-menu a {
  color: #545454;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
  margin-top: 1.9rem;
  display: block;
}

.footer-menu a:hover {
  color: #7bbc00;
}

.footer-menu > ul > li {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 23.2317609825%;
}

.footer-menu > ul > li:last-child {
  margin-right: 0;
}

.footer-menu > ul > li:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1204px) {
  .footer-menu > ul > li {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .footer-menu > ul > li:last-child {
    margin-right: 0;
  }
  .footer-menu > ul > li:nth-child(2n) {
    margin-right: 0;
  }
  .footer-menu > ul > li:nth-child(2n+1) {
    clear: left;
  }
  .footer-menu > ul > li:nth-child(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 462px) {
  .footer-menu > ul > li {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    margin-top: 3rem;
  }
  .footer-menu > ul > li:last-child {
    margin-right: 0;
  }
  .footer-menu > ul > li:first-child {
    margin-top: 0;
  }
}

.footer-menu > ul > li > a {
  margin-top: 0;
  font-weight: 600;
  color: #272727;
  font-size: 1.8rem;
}

/*footer menu bottom */
.footer-bottom-menu {
  float: left;
}

.footer-bottom-menu li {
  float: left;
  padding: 0 1rem;
  position: relative;
}

.footer-bottom-menu li:after {
  content: "";
  width: 1px;
  height: 80%;
  background-color: #898989;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 0.4rem;
}

.footer-bottom-menu li:first-child {
  padding-left: 0;
}

.footer-bottom-menu li:last-child {
  padding-right: 0;
}

.footer-bottom-menu li:last-child:after {
  content: none;
}

.footer-bottom-menu a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  color: #898989;
  border-bottom: 1px solid #898989;
  transition: all 0.25s ease-in-out;
}

.footer-bottom-menu a:hover {
  border-bottom: 1px solid #7bbc00;
  color: #7bbc00;
}

@keyframes menu {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.submenu .header--image,
.submenu .header__image--image {
  margin-top: 46px;
}

@media screen and (max-width: 600px) {
  .submenu .header--image,
  .submenu .header__image--image {
    margin-top: 0;
  }
}

.contact-block {
  float: right;
}

@media screen and (max-width: 650px) {
  .contact-block {
    display: none;
  }
}

.contact-block--1 {
  padding: 3rem 2.5rem;
  background-size: cover;
  position: relative;
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.25);
  width: calc(100% + 5rem);
  position: relative;
  left: -2.5rem;
}

.contact-block--1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.contact__content--1 {
  position: relative;
  z-index: 10;
}

.contact-block__outer {
  margin-top: 5.5rem;
}

.contact-block__content {
  color: #ffffff;
  font-size: 1.8rem;
  padding: 1.2rem 2rem;
  background-color: #7bbc00;
  font-family: "Century Gothic";
}

.contact-block__content p {
  display: block;
  float: left;
  margin-top: 0.3rem;
}

.contact-block__icon {
  width: 2rem;
  height: 2.5rem;
  fill: #ffffff;
  display: block;
  float: left;
  padding-right: 0.3rem;
}

.contact-block--footer {
  float: none;
}

.contact__left {
  float: left;
}

.contact__left h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.2;
  color: #7bbc00;
}

.contact__left h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 0.5rem;
}

.contact__right {
  float: right;
}

@media screen and (max-width: 1114px) {
  .contact__right {
    float: left;
    clear: both;
    margin-top: 1rem;
    margin-left: 5rem;
  }
}

@media screen and (max-width: 404px) {
  .contact__right {
    margin-left: 0;
  }
}

.contact__content {
  padding: 3rem;
  background-color: #272727;
}

@media screen and (max-width: 404px) {
  .contact__content {
    padding: 3rem 0;
  }
}

.contact__title,
.contact__subtitle {
  margin-left: 5.5rem;
}

@media screen and (max-width: 404px) {
  .contact__title,
  .contact__subtitle {
    margin-left: 0;
  }
}

.contact__icon {
  display: block;
  float: left;
}

.contact__icon--phone {
  width: 2.5rem;
  height: 2rem;
  fill: #ffffff;
  margin-right: 0.7rem;
  margin-top: -0.4rem;
}

.contact__icon--bulb {
  fill: #7bbc00;
  width: 3.6rem;
  height: 4rem;
}

@media screen and (max-width: 404px) {
  .contact__icon--bulb {
    display: none;
  }
}

.contact__content p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: #ffffff;
  display: block;
  float: left;
}

.contact__button {
  float: left;
  margin-top: 1.5rem;
  margin-right: 2.5rem;
}

@media screen and (max-width: 583px) {
  .contact__button-style {
    clear: both;
    float: left;
    margin-top: 2rem;
  }
}

.mm-menu .contact-block {
  padding: 0;
  width: 100%;
  float: left;
}

@media screen and (max-width: 562px) {
  .mm-menu .contact-block {
    display: block;
  }
}

.button-style {
  color: #ffffff;
  background-color: #7bbc00;
  font-size: 1.9rem;
  padding: 3rem 3rem;
  border-radius: 9999px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  transition: all 0.25s ease-in-out;
  display: inline-block;
  border: none;
  background-color: #7bbc00;
  background-image: linear-gradient(#85cb00, #7bbc00, #71ad00);
  background-size: 100% 200%;
  animation: attention 3s infinite;
  will-change: background-position;
}

.button-style:hover {
  background: #6aa300;
}

.button-style--small {
  font-size: 1.4rem;
  padding: 1rem 2.5rem;
  font-weight: 600;
  position: relative;
}

.button-style--medium {
  padding: 1.4rem 2.5rem;
}

.button-style--icon {
  position: relative;
  padding-left: 7.2rem;
}

.button-style--icon:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM9,17V7l9,5.15Z'/%3E%3C/svg%3E");
  width: 4rem;
  height: 100%;
  background-size: 3.6rem;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: 2.3rem;
  top: 50%;
  transform: translateY(-50%);
}

.button-style--download {
  position: relative;
  padding-left: 4.8rem;
  font-size: 1.4rem;
}

.button-style--download:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 18'%3E%3Cpath d='M12,2c3.45,0,5.89,2.8,5.57,6.78,1.75,0,4.43.75,4.43,3.72A3.5,3.5,0,0,1,18.5,16H5.5A3.5,3.5,0,0,1,2,12.5C2,9.7,4.48,8.67,6.43,8.78,6.27,4.56,8.64,2,12,2Zm0-2A7.49,7.49,0,0,0,4.52,7.09,5.5,5.5,0,0,0,5.5,18h13a5.5,5.5,0,0,0,1-10.91A7.49,7.49,0,0,0,12,0ZM8,10h3V6h2v4h3l-4,4Z'/%3E%3C/svg%3E");
  width: 2rem;
  height: 100%;
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
}

.button-style--next {
  font-size: 1.4rem;
  padding: 1rem 2.5rem;
  padding-right: 3.8rem;
  font-weight: 600;
  position: relative;
}

.button-style--next:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  background-size: 0.9rem;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 2rem;
  width: 0.6rem;
  height: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.button-style--next:hover:before {
  animation: arrow-next-center 2s ease-in-out infinite;
}

.button-style--next--medium {
  padding: 1.4rem 2.5rem;
  padding-right: 3.8rem;
}

.button-style--white {
  background: transparent;
  border: 1px solid white;
}

.button-style--white:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.button-style--green {
  background: transparent;
  border: 1px solid #7bbc00;
  color: #7bbc00;
}

.button-style--green:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237bbc00' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
}

.button-style--green:hover {
  background-color: transparent;
}

.button-style--webform {
  padding: 0;
}

.button-style--webform input {
  padding: 1.4rem 2.5rem;
  padding-right: 3.8rem;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 600;
}

.link-style--next {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #7bbc00;
  font-size: 1.4rem;
}

.link-style--next:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237bbc00' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  background-size: 0.9rem;
  background-repeat: no-repeat;
  background-position: center center;
  width: 0.6rem;
  height: 1rem;
  display: inline-block;
  padding-left: 2rem;
}

.link-style--next:hover:after {
  animation: arrow-next 2s ease-in-out infinite;
}

.link-style--prev {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #7bbc00;
  font-size: 1.4rem;
}

.link-style--prev:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237bbc00' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  background-size: 0.9rem;
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 0.6rem;
  height: 1rem;
  display: inline-block;
  padding-left: 2rem;
}

.link-style--prev:hover:after {
  animation: arrow-next 2s ease-in-out infinite;
}

.link-style--down {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #7bbc00;
  font-size: 2rem;
  text-decoration: underline;
}

.link-style--down:hover {
  cursor: pointer;
}

.link-style--down:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg fill='%237bbc00' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM9,17V7l9,5.15Z'/%3E%3C/svg%3E");
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  transform: rotate(90deg);
  position: relative;
  top: 0.3rem;
  margin-right: 0.6rem;
}

@keyframes arrow-next-center {
  0% {
    transform: translateX(0) translateY(-50%);
  }
  20% {
    transform: translateX(5px) translateY(-50%);
  }
  50% {
    transform: translateX(0) translateY(-50%);
  }
  80% {
    transform: translateX(5px) translateY(-50%);
  }
  100% {
    transform: translateX(0) translateY(-50%);
  }
}

@keyframes arrow-next {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes arrow-prev {
  0% {
    transform: translateX(0) rotate(180deg);
  }
  20% {
    transform: translateX(-5px) rotate(180deg);
  }
  50% {
    transform: translateX(0) rotate(180deg);
  }
  80% {
    transform: translateX(-5px) rotate(180deg);
  }
  100% {
    transform: translateX(0) rotate(180deg);
  }
}

@keyframes attention {
  0%, 100% {
    background-position: center top;
  }
  50% {
    background-position: center bottom;
  }
}

.social-media {
  float: right;
}

@media screen and (max-width: 742px) {
  .social-media {
    margin-top: 5rem;
    float: left;
  }
}

@media screen and (max-width: 462px) {
  .social-media {
    margin-top: 3rem;
  }
}

.social-media__title {
  font-weight: 600;
  color: #272727;
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 1.9rem;
}

.social-media__container {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #272727;
  display: inline-block;
  position: relative;
  margin-right: 1rem;
  transition: all 0.3s ease-in-out;
}

.social-media__container:last-child {
  margin-right: 0;
}

.social-media__container:hover {
  transform: scale(1.1);
  border: 1px solid #272727;
  background-color: transparent;
}

.social-media__container:hover .social-media__icon {
  fill: #272727;
}

.social-media__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  fill: #ffffff;
  transform: translate(-50%, -50%);
  width: 1.7rem;
  height: 1.7rem;
  transition: all 0.3s ease-in-out;
}

.social-media--footer {
  width: 100%;
  max-width: 40rem;
}

@media screen and (max-width: 1063px) {
  .social-media--footer {
    clear: both;
    float: left;
    margin-top: 5rem;
  }
}

.social-media--footer .social-media__content {
  clear: both;
}

.social-media--footer .social-media__content::after {
  clear: both;
  content: "";
  display: block;
}

.pager {
  margin-top: 10rem;
}

.pager::after {
  clear: both;
  content: "";
  display: block;
}

.pager li {
  float: left;
}

.pager li:not(.pager-next):not(.pager-previous) {
  border: 1px solid #dfdfdf;
  border-left: none;
}

.pager li:not(.pager-next):not(.pager-previous):first-child {
  border-left: 1px solid #dfdfdf;
}

.pager a,
.pager .pager-current {
  color: #1b1b1b;
  padding: 2rem;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1rem;
}

.pager-next a {
  color: #7bbc00;
}

.pager-next a:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237bbc00' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  background-size: 0.9rem;
  background-repeat: no-repeat;
  background-position: center center;
  width: 0.6rem;
  height: 1rem;
  display: inline-block;
  padding-left: 2rem;
}

.pager-next a:hover:after {
  animation: arrow-next 2s ease-in-out infinite;
}

.pager-previous a {
  color: #7bbc00;
}

.pager-previous a:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237bbc00' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  background-size: 0.9rem;
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 0.6rem;
  height: 1rem;
  position: relative;
  top: 0.1rem;
  display: inline-block;
  padding-right: 2rem;
}

.pager-previous a:hover:before {
  animation: arrow-prev 2s ease-in-out infinite;
}

.pager-current {
  color: #7bbc00 !important;
}

.header {
  height: 840px;
  width: 100%;
  position: relative;
  margin-top: 12.8rem;
}

.header__content {
  text-align: center;
}

.header__content h1 {
  font-family: "Century Gothic Bold";
  color: #ffffff;
  font-size: 3.6rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.header__content strong {
  color: #7bbc00;
}

.header__content p {
  font-size: 2.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #ffffff;
  margin-top: 3rem;
  margin-bottom: 10rem;
}

@media screen and (max-width: 1142px) {
  .header__content p {
    margin-bottom: 7rem;
  }
}

@media screen and (max-width: 992px) {
  .header__content p {
    margin-bottom: 5rem;
  }
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 15;
}

.main-navigation {
  border-bottom: 1px solid #e0e0e0;
}

.header__image--image {
  background-size: cover;
  height: 55vh;
  min-height: 62.8rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  position: fixed;
  overflow: hidden;
}

@media screen and (max-height: 600px) {
  .header__image--image {
    min-height: 62.8rem;
  }
}

.header--image {
  min-height: 62.8rem;
  height: 55vh;
  position: relative;
  z-index: 1;
}

.header--image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.38) 0%, rgba(255, 255, 255, 0.38) 100%);
}

@media screen and (max-height: 600px) {
  .header--image {
    min-height: 50rem;
  }
}

.header__content--image .node__name {
  background-color: #000000;
}

.header__content--image .node__title {
  color: #ffffff;
}

.header__content--image .node__title:after {
  background-color: #ffffff;
}

.header__content--image {
  padding-top: 10rem;
  margin-top: 12.8rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}

.header__content--video {
  font-size: 1rem;
  padding-top: 14rem;
  margin-top: 0;
  position: fixed;
  z-index: 6;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-height: 800px) {
  .header__content--video {
    font-size: 0.9rem;
  }
}

@media screen and (max-height: 700px) {
  .header__content--video {
    font-size: 0.8rem;
  }
}

@media screen and (max-height: 600px) {
  .header__content--video {
    font-size: 0.7rem;
  }
}

@media screen and (max-height: 500px) {
  .header__content--video {
    font-size: 0.6rem;
  }
}

@media screen and (max-height: 470px) {
  .header__content--video {
    top: 0;
    position: relative;
    margin-top: 1.8rem;
    transform: translate(-50%, 0);
  }
}

@media screen and (max-width: 861px) {
  .header__content--video {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 784px) {
  .header__content--video {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 689px) {
  .header__content--video {
    font-size: 0.7rem;
  }
}

.header__content--video h1 {
  font-size: 3.6em;
}

.header__content--video p {
  font-size: 2.5em;
  margin-top: 1.2em;
  margin-bottom: 2.2em;
}

.header__content--video .header__button {
  font-size: 1.9em;
  padding: 1.5789473684em 2.6315789474em;
  padding-left: 3.7894736842em;
  width: 21.5789473684em;
}

.header__content--video .header__button:before {
  width: 2.1052631579em;
  background-size: 1.8947368421em;
  left: 1.2105263158em;
}

@media screen and (max-width: 650px) {
  .header__content--video .header__button {
    display: block;
    min-width: 40rem;
    margin: 0 auto;
  }
}

.header__content--video .header__button--white {
  padding: 1.5789473684em 2.6315789474em;
  background: #FFFFFF;
  color: #7bbc00;
  margin-left: 3rem;
}

@media screen and (max-width: 650px) {
  .header__content--video .header__button--white {
    margin: 3rem auto 0 auto;
  }
}

.header__content--video .header__button--white:hover {
  background: #f2f2f2;
}

.header__image--video {
  height: 100vh;
  min-height: 43rem;
  z-index: 5;
}

@media screen and (max-height: 470px) {
  .header__image--video {
    position: absolute;
  }
}

.header--video {
  height: 100vh;
  min-height: 43rem;
  z-index: 3;
  position: relative;
}

@media screen and (max-height: 470px) {
  .header--video {
    margin-bottom: 8rem;
  }
}

.header--video:after {
  content: none;
}

.icon-scroll--video {
  bottom: 1rem;
  position: fixed;
  z-index: 5;
}

@media screen and (max-height: 470px) {
  .icon-scroll--video {
    position: absolute;
  }
}

html.mm-right.mm-opening .mm-menu ~ .mm-slideout.header__content--video {
  transform: translate3d(calc(-440px - 50%), -50%, 0);
}

.newsletter .links {
  display: none;
}

.newsletter .webform-confirmation {
  padding: 0;
  color: #7e7e7e;
  text-align: left;
}

@media screen and (max-width: 1128px) {
  .jobs--full .webform,
  .contact--full .webform {
    margin-bottom: 0;
  }
}

.webform {
  width: calc(100% + 5rem);
  position: relative;
  left: -2.5rem;
  background-color: #272727;
  margin-bottom: 6rem;
}

.webform .error li {
  line-height: 1.5;
}

.webform__intro {
  padding: 3rem 4rem;
  border-bottom: 1px solid #494949;
}

.webform__extra {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.2rem;
  color: #9a9a9a;
  margin-left: 2rem;
}

.webform__submit {
  clear: both;
  float: left;
  margin-top: 3rem;
}

.webform__content, .webform__result-pdf {
  padding: 3rem 4rem;
}

.webform__content .form-actions, .webform__result-pdf .form-actions {
  display: inline-block;
}

.webform__content label, .webform__result-pdf label {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
}

.webform__content > .form-item, .webform__result-pdf > .form-item {
  float: left;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
  margin-top: 3.5rem;
}

.webform__content > .form-item:nth-child(1), .webform__content > .form-item:nth-child(2), .webform__result-pdf > .form-item:nth-child(1), .webform__result-pdf > .form-item:nth-child(2) {
  margin-top: 0;
}

.webform__content > .form-item:last-child, .webform__result-pdf > .form-item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 420px) {
  .webform__content > .form-item, .webform__result-pdf > .form-item {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .webform__content > .form-item:last-child, .webform__result-pdf > .form-item:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 420px) and (max-width: 420px) {
  .webform__content > .form-item, .webform__result-pdf > .form-item {
    max-width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .webform__content > .form-item:nth-child(2), .webform__result-pdf > .form-item:nth-child(2) {
    margin-top: 3.5rem;
  }
}

.webform__content > .form-item.right, .webform__result-pdf > .form-item.right {
  margin-right: 0;
}

.webform__content > .form-item > label, .webform__result-pdf > .form-item > label {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #ffffff;
  line-height: 1;
  font-style: italic;
  color: #ffffff;
  margin-bottom: 1rem;
  display: block;
}

.webform__content > .form-item > label.error, .webform__result-pdf > .form-item > label.error {
  color: #c71a2a;
}

.webform__content .webform-component-checkboxes, .webform__result-pdf .webform-component-checkboxes {
  width: 100%;
}

.webform__content .webform-component-checkboxes label, .webform__result-pdf .webform-component-checkboxes label {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;
}

.webform__content input[type="text"],
.webform__content input[type="email"],
.webform__content input[type="password"], .webform__result-pdf input[type="text"],
.webform__result-pdf input[type="email"],
.webform__result-pdf input[type="password"] {
  max-width: 35rem;
  width: 100%;
  background: transparent;
  border: none;
  border-radius: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #ffffff;
  line-height: 1;
  padding: 0.5rem 0;
}

@media screen and (max-width: 420px) {
  .webform__content input[type="text"],
  .webform__content input[type="email"],
  .webform__content input[type="password"], .webform__result-pdf input[type="text"],
  .webform__result-pdf input[type="email"],
  .webform__result-pdf input[type="password"] {
    max-width: 100%;
  }
}

.webform__content input[type="text"]::placeholder,
.webform__content input[type="email"]::placeholder,
.webform__content input[type="password"]::placeholder, .webform__result-pdf input[type="text"]::placeholder,
.webform__result-pdf input[type="email"]::placeholder,
.webform__result-pdf input[type="password"]::placeholder {
  font-style: italic;
  color: #ffffff;
}

.webform__content textarea, .webform__result-pdf textarea {
  width: 100%;
  background-color: #303030;
  border: 1px solid #545454;
  resize: none;
  min-height: 12.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #ffffff;
  line-height: 1;
  padding: 1rem;
}

.webform__content .webform-component-textarea, .webform__result-pdf .webform-component-textarea {
  width: 100%;
  clear: both;
}

#edit-submitted-upload-cv-and-motivation-letter-ajax-wrapper {
  width: 100%;
  clear: both;
}

.webform__content > .webform-component-inbox25 {
  width: 100%;
}

.webform__content > .webform-component-inbox25 > label {
  font-size: 1.4rem;
  margin-bottom: 0;
  font-style: normal;
}

.form-type-checkbox,
.webform-component-inbox25 {
  padding: 1rem 0;
  float: left;
  margin-right: 3rem;
}

.form-type-checkbox .form-checkboxes,
.webform-component-inbox25 .form-checkboxes {
  clear: both;
  float: left;
}

.form-type-checkbox::after,
.webform-component-inbox25::after {
  clear: both;
  content: "";
  display: block;
}

.form-type-checkbox input[type="checkbox"],
.webform-component-inbox25 input[type="checkbox"] {
  display: none;
}

.form-type-checkbox label,
.webform-component-inbox25 label {
  padding-left: 3rem;
  position: relative;
  float: left;
}

.form-type-checkbox label:before,
.webform-component-inbox25 label:before {
  background-color: transparent;
  /* have to be of the same color */
  box-shadow: 0px 0px 0px 1px #7bbc00;
  /* have to be of the same color */
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: " ";
}

.form-type-checkbox input[type="checkbox"]:checked + label:before,
.webform-component-inbox25 input[type="checkbox"]:checked + label:before {
  background-color: #7bbc00;
}

.form-type-checkbox input[type="checkbox"]:checked + label:after,
.webform-component-inbox25 input[type="checkbox"]:checked + label:after {
  content: " ";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 11c2.761.575 6.312 1.688 9 3.438 3.157-4.23 8.828-8.187 15-11.438-5.861 5.775-10.711 12.328-14 18.917-2.651-3.766-5.547-7.271-10-10.917z'/%3E%3C/svg%3E");
  background-size: 12px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
}

.form-type-radio {
  padding: 1rem 0;
  float: left;
  margin-right: 3rem;
}

.form-type-radio::after {
  clear: both;
  content: "";
  display: block;
}

.form-type-radio input[type="radio"] {
  display: none;
}

.form-type-radio label {
  padding-left: 3rem;
  position: relative;
}

.form-type-radio label:before {
  background-color: #ffffff;
  /* have to be of the same color */
  box-shadow: 0px 0px 0px 1px #7bbc00;
  /* have to be of the same color */
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: " ";
  border-radius: 50%;
}

.form-type-radio input[type="radio"]:checked + label:before {
  border: 4px solid #ffffff;
  background-color: #7bbc00;
  border-radius: 50%;
}

.webform-component-markup h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 2.4rem;
  color: #ffffff;
}

.webform-component-markup p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #7bbc00;
  margin-top: 1rem;
}

.webform .links {
  display: none;
}

.webform-confirmation {
  padding: 4rem;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
}

.webform--jobs {
  width: 100%;
  left: 0;
  margin-top: 5rem;
}

.webform-component--multi {
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 600;
  width: 100%;
}

.webform-component--multi thead {
  display: none;
}

.webform-component--multi tbody {
  display: table;
  width: 100%;
}

.webform-component--multi tbody input[type="submit"] {
  font-size: 1.2rem;
  padding: 0.4rem 1.6rem;
  color: #ffffff;
  border-radius: 9999px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  transition: all 0.25s ease-in-out;
  display: inline-block;
  border: none;
  background-color: transparent;
  animation: attention 3s infinite;
  will-change: background-position;
}

.webform-component--multi tbody input[type="submit"]:hover {
  background-color: #5a8900;
}

.webform-component--multi legend {
  display: none;
}

.webform-component--multi th {
  display: none;
}

.webform-component--multi td:not(.tabledrag-hide) {
  display: inline-block !important;
  width: 50%;
  vertical-align: middle;
}

.webform-component--multi td:last-child {
  text-align: right;
}

.webform-component--multi a {
  font-weight: 700;
  color: #3D3D3D;
  text-decoration: none;
}

.webform-component--multi tr {
  padding-bottom: 7px;
  padding-top: 7px;
  width: 100%;
  border-bottom: 1px solid #303030;
  display: inline-block !important;
}

.webform-component--multi table,
.webform-component--multi .webform-component-multiple-file {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 100%;
  margin-right: 0;
}

.webform-component--multi table:last-child,
.webform-component--multi .webform-component-multiple-file:last-child {
  margin-right: 0;
}

@media screen and (max-width: 706px) {
  .webform-component--multi table,
  .webform-component--multi .webform-component-multiple-file {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    margin-right: 0;
  }
  .webform-component--multi table:last-child,
  .webform-component--multi .webform-component-multiple-file:last-child {
    margin-right: 0;
  }
}

.webform-component--multi .tabledrag-toggle-weight-wrapper {
  display: none;
}

.webform-component--multi .tabledrag-changed,
.webform-component--multi .tabledrag-changed-warning {
  display: none;
}

.webform-component--multi .form-managed-file {
  float: left;
}

.webform-component--multi .description {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.2rem;
  color: #9a9a9a;
  margin-left: 2rem;
  float: left;
  margin-top: 1.4rem;
}

.webform-component--multi .draggable a.tabledrag-handle {
  height: 1.6rem;
}

table + .webform-component-multiple-file {
  margin-top: 3rem;
}

.form-managed-file .file-icon {
  display: none;
}

.form-managed-file .file a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #7bbc00;
  font-style: italic;
  line-height: 1;
}

.form-managed-file .file-size {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.2rem;
  color: #9a9a9a;
}

.webform--whitepaper .messages.error,
.webform--jobs .messages.error {
  padding: 3rem 4rem 0 4rem;
  margin-bottom: 0;
}

.webform--whitepaper label,
.webform--jobs label {
  color: #ffffff;
}

.webform--white {
  margin-top: 5rem;
  width: 100%;
  left: 0;
  background-color: #ffffff;
}

.webform--white label {
  color: #7bbc00;
}

.webform--white .webform-component--multi tr {
  border-bottom: 1px solid #e0e0e0;
}

.webform--white .upload-fake {
  border: 1px solid #7bbc00;
  color: #7bbc00;
}

.webform--white .upload-fake:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237bbc00' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
}

.webform--white .webform-confirmation {
  color: #000000;
  background-color: #f7f7f7;
}

.webform--white .webform__content {
  padding: 0;
}

.webform--white .webform__content input[type="text"],
.webform--white .webform__content input[type="email"],
.webform--white .webform__content input[type="password"] {
  border-bottom: 1px solid #7bbc00;
  color: #000000;
  font-weight: 600;
}

.webform--white .webform__content input[type="text"]::placeholder,
.webform--white .webform__content input[type="email"]::placeholder,
.webform--white .webform__content input[type="password"]::placeholder {
  color: #7bbc00;
  font-weight: 400;
}

.webform--white .webform__content > .form-item > label {
  color: #7bbc00;
}

.webform--white .webform__content textarea {
  background-color: #f1fbe3;
  border: none;
  border-bottom: 1px solid #7bbc00;
  color: #000000;
}

.webform-component-select {
  border-bottom: 1px solid #7bbc00;
  max-width: 35rem;
  margin-top: 4rem !important;
}

.webform-component-select .selectric {
  border: none;
  height: 2.6rem;
}

.webform-component-select .selectric .button {
  height: 2rem;
}

.webform-component-select .selectric-above .selectric-items {
  border: none;
}

.webform-component-select .selectric-items li {
  border: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #000000;
  transition: all 0.25s ease-in-out;
}

.webform-component-select .selectric-items li.highlighted {
  color: #7bbc00;
  font-weight: 700;
}

.webform-component-select .selectric-items li:hover {
  color: #7bbc00;
}

.webform-component-select .selectric .label {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #000000;
  height: 2rem;
  line-height: 2rem;
}

.webform-component-select .selectric-wrapper {
  width: 70%;
  clear: none;
  float: left;
}

.webform-component-select label {
  width: 30% !important;
  float: left;
  margin-bottom: 0 !important;
  line-height: 2rem !important;
}

.webform-component-select .selectric-open .selectric-items {
  border: none;
  border-top: 1px solid #7bbc00;
}

.error {
  display: block;
  color: #c71a2a !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  font-style: italic;
}

.messages.error {
  margin-bottom: 4rem;
}

.webform--login {
  position: static;
}

.webform--login .description {
  padding-top: 0.6rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  font-style: italic;
}

.webform--login label {
  display: none;
}

.webform--login .form-actions {
  margin-top: 2.5rem;
}

.webform--login .form-item {
  margin-top: 1rem;
}

.webform--login .webform__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.webform--login .messages li {
  margin-top: 0.8rem;
}

.webform--reset input[type="submit"] {
  border-radius: 9999px;
  padding: 1.4rem 2.5rem;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 600;
  background-color: #7bbc00;
}

.audit-form__wrapper {
  background-color: #FFF;
  z-index: 5;
  position: relative;
}

.audit-form {
  border: #7bbc00 5px solid;
  max-width: 122rem;
  margin-left: auto;
  margin-right: auto;
  padding: 3.5rem 6.5rem 5rem;
  clear: both;
  margin-bottom: 15rem;
  margin-bottom: 10rem;
}

.audit-form::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 856px) {
  .audit-form {
    padding: 3.5rem 2.5rem 5rem;
  }
}

.audit-form .form-item {
  float: left;
  position: relative;
  width: 100%;
}

.audit-form .form-item.right {
  float: right;
  width: 45%;
}

@media screen and (max-width: 640px) {
  .audit-form .form-item.right {
    order: 2;
    margin-top: 28px;
    width: 100%;
  }
}

.audit-form .form-item.clear {
  clear: both;
  width: calc(45% - 30px);
  margin-left: 30px;
  margin-right: 5%;
}

@media screen and (max-width: 640px) {
  .audit-form .form-item.clear {
    order: 1;
    width: calc(100% - 30px);
  }
}

.audit-form .form-item label {
  position: absolute;
  top: 6px;
  left: 0;
  color: #1b1b1b;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  font-style: unset;
}

.audit-form .form-item input {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 1;
  padding-left: 50%;
  width: 100%;
  max-width: 100%;
  font-style: italic;
  display: block;
  color: #1b1b1b;
  border-bottom: 1px solid #818181;
}

@media screen and (max-width: 420px) {
  .audit-form .form-item input {
    padding-left: 75%;
  }
}

.audit-form .form-item input::placeholder {
  color: #1b1b1b;
  font-weight: 700;
}

.audit-form .form-item .error {
  margin-bottom: 5px;
  padding-left: 50%;
}

@media screen and (max-width: 420px) {
  .audit-form .form-item .error {
    padding-left: 75%;
  }
}

.audit-form .form-item input.error {
  color: #1b1b1b !important;
  margin-bottom: 0;
}

.audit-form .webform__submit {
  margin-top: 4rem;
}

.audit-form .form-radios .form-type-radio {
  display: inline-block;
  width: 45%;
  margin-right: 5%;
  position: relative;
}

.audit-form .form-radios .form-type-radio input {
  display: none;
}

.audit-form .webform-component--bedrijfsgrootte {
  position: relative;
}

.audit-form .webform-component--bedrijfsgrootte .option__info {
  content: "";
  background: url("../svg/question-icon.svg");
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 80px;
  top: 4px;
}

.audit-form .webform-component--bedrijfsgrootte .description {
  position: absolute;
  background-color: #323232;
  color: #ffffff;
  z-index: 10;
  bottom: calc(-100% + 50px);
  left: calc(-86% + 86px);
  font-size: 1.5rem;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  width: 0;
  opacity: 0;
}

.audit-form .webform-component--bedrijfsgrootte .description li {
  margin-top: 5px;
}

.audit-form .webform-component--bedrijfsgrootte .description li:first-child {
  margin-top: 0;
}

.audit-form .webform-component--bedrijfsgrootte .description a {
  color: #ffffff;
  text-decoration: underline;
}

.audit-form .webform-component--bedrijfsgrootte .description:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #323232;
  border-bottom-color: #323232;
  transform: rotate(45deg);
}

.audit-form .webform__content {
  background: #f8f8f8 url("../img/audit-calculation.jpg") right bottom no-repeat;
  background-size: 35%;
  position: relative;
  padding: 4.5rem 3.5rem;
}

@media screen and (max-width: 640px) {
  .audit-form .webform__content {
    display: flex;
    flex-direction: column;
  }
}

.audit-form .webform__intro {
  border-bottom: none;
  padding: 1rem 0 2rem;
}

.audit-form .webform-component--intro {
  color: #282828;
  font-size: 1.75rem;
}

.audit-form .webform-component--intro p {
  font-size: 1.75rem;
  color: #282828;
  font-weight: 400;
}

.audit-form .webform-component--intro h2 {
  color: #7bbc00;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.375rem;
  margin: 2.375rem 0;
}

.audit-form .webform-component--elektriciteit {
  position: relative;
}

.audit-form .webform-component--elektriciteit:before {
  content: "";
  background-image: url("../svg/elek-icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 3px;
  display: block;
}

.audit-form .webform-component--gas {
  position: relative;
}

.audit-form .webform-component--gas:before {
  content: "";
  background-image: url("../svg/waves-icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 3px;
  display: block;
}

.audit-form .webform-component--stookolie {
  position: relative;
}

.audit-form .webform-component--stookolie:before {
  content: "";
  background-image: url("../svg/gas-icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 3px;
  display: block;
}

.audit-form .webform-component--brandstof-wagens {
  position: relative;
}

.audit-form .webform-component--brandstof-wagens:before {
  content: "";
  background-image: url("../svg/car-icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 3px;
  display: block;
}

.audit-form .webform-component--succes-box div, .audit-form .webform-component--succes-box p {
  display: inline;
  font-family: "Open Sans", sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
}

.audit-form .webform-component--succes-box div strong, .audit-form .webform-component--succes-box p strong {
  font-weight: 600;
}

.audit-form .webform-component--pdf-group--stap-1---footer p {
  display: inline-block;
  clear: both;
  float: left;
}

.audit-form .webform-component--pdf-group--stap-1---footer #value__invest-cost {
  display: inline-block;
  float: left;
  color: #1b1b1b;
  font-weight: 300;
  line-height: 1.6;
  margin-top: 1rem;
}

.audit-form .form-actions {
  float: left;
  margin-right: 30px;
}

@media screen and (max-width: 640px) {
  .audit-form .webform__submit {
    order: 10;
  }
}

.audit-form .webform-component--contact-block {
  padding: 3rem 2.5rem;
  background: url("../img/contacteer_ons_1600.jpg");
  background-size: cover;
  position: relative;
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.25);
  width: calc(100% + 5rem);
  position: relative;
  left: -2.5rem;
}

.audit-form .webform-component--contact-block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.audit-form .webform-component--contact-block a {
  position: relative;
  display: block;
}

.audit-form .webform-component--contact-block a:after {
  display: table;
  content: '';
  clear: both;
}

.audit-form .webform-component--contact-block:after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: url("../svg/bulb-green.svg");
  background-size: cover;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (max-width: 420px) {
  .audit-form .webform-component--contact-block:after {
    content: none;
  }
}

.audit-form .webform__extra {
  float: left;
  color: #282828;
}

@media screen and (max-width: 640px) {
  .audit-form .webform__extra {
    clear: both;
    margin-top: 20px;
  }
}

.audit-form .webform__extra label, .audit-form .webform__extra span {
  position: static;
  display: inline;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
}

.audit-form .webform__extra input {
  width: 50px;
  padding: 0;
  display: inline-block;
  font-size: 2rem;
  font-weight: 700;
  border: none;
  font-style: normal;
}

.audit-form .webform__extra span {
  font-weight: 700;
}

.audit-form .webform__extra .webform-component--uw-verbruik {
  display: none;
}

.audit-form .webform__footer {
  margin-top: 7.2rem;
  margin-bottom: 1.5rem;
}

.audit-form .webform__footer .webform-component-markup {
  padding: 3rem 3rem 3rem 8.5rem;
  color: #FFF;
  font-size: 1.75rem;
  display: none;
  background-color: #7bbc00;
}

.audit-form .webform__footer .webform-component-markup.webform-component--rood, .audit-form .webform__footer .webform-component-markup.webform-component--geen-audit {
  background-color: #ea2a43;
}

.audit-form .webform__footer .webform-component-markup.webform-component--succes-box {
  padding: 3rem;
  line-height: 1.2;
}

.audit-form .webform__footer .webform-component-markup.webform-component--succes-box h2 {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
  font-weight: 700;
}

.audit-form .webform__footer .webform-component-markup.webform-component--succes-box p {
  display: block;
  clear: both;
  float: left;
  margin: 0;
}

.audit-form .webform__footer .webform-component-markup.webform-component--succes-box div {
  float: left;
}

.audit-form .webform__footer .webform-component-markup.webform-component--succes-box:before {
  content: none;
}

.audit-form .webform__footer .webform-component-markup strong {
  font-size: 1.875rem;
}

.audit-form .webform__footer .webform-component-markup p {
  color: #FFF;
}

.audit-form .webform__footer .webform-component-markup a {
  color: #282828;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-out;
}

.audit-form .webform__footer .webform-component-markup a:hover {
  border-bottom: 1px solid #282828;
}

.audit-form .webform__footer .webform-component-markup .button {
  display: inline-block;
  float: right;
  color: #ffffff;
  background-color: #181818;
  font-size: 1.9rem;
  padding: 2rem 5rem 2rem 3rem;
  border-radius: 9999px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border: none;
  position: relative;
}

.audit-form .webform__footer .webform-component-markup .button:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 3rem;
  width: 0.6rem;
  height: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.audit-form .webform__footer .webform-component-markup .button:hover {
  border: none;
}

.audit-form .webform__footer .webform-component-markup .button:hover:after {
  animation: arrow-next-center 2s ease-in-out infinite;
}

.audit-form .webform__footer .webform-component-markup:before {
  content: "";
  background-image: url("../svg/info-icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 4rem;
  height: 4rem;
  position: absolute;
  left: 3rem;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
}

.audit-form .webform__result .webform-component-markup {
  display: none;
  margin-bottom: 3.5rem;
  margin-top: 1.5rem;
}

.audit-form .webform__result .webform-component-markup p {
  color: #1b1b1b;
  font-weight: 300;
  line-height: 1.6;
}

.audit-form .webform__result .webform-component-markup h2 {
  font-weight: 700;
  font-size: 2.1rem;
  color: #1b1b1b;
  line-height: 1.2;
  margin-bottom: 2.5rem;
}

.audit-form .webform__result .webform-component-markup h2 strong {
  color: #7bbc00;
}

.audit-form .webform__result .webform-component-markup ul {
  list-style: disc;
  font-family: "Open Sans", sans-serif;
}

.audit-form .webform__result .webform-component-markup ul li {
  color: #1b1b1b;
  font-weight: 300;
  line-height: 1.6;
  margin-left: 15px;
}

.audit-form .webform__result .webform-component-markup .small, .audit-form .webform__result .webform-component-markup .small p {
  font-size: 1.3rem;
}

.audit-form .webform__result .webform-component-markup .contact__left h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.2;
  color: #7bbc00;
  margin-bottom: 0;
}

.audit-form .webform__result .webform-component-markup .contact__left h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 0.5rem;
}

.audit-form .webform__result .webform-component--titel-besparing {
  margin-bottom: 0;
}

.audit-form .table {
  margin-top: 3rem;
  color: #1b1b1b;
  font-weight: 300;
}

.audit-form .table__row {
  clear: both;
}

@media screen and (max-width: 800px) {
  .audit-form .table__row {
    background-color: #f8f8f8;
    margin-bottom: 10px;
  }
  .audit-form .table__row:after {
    display: table;
    content: "";
    clear: both;
  }
}

.audit-form .table__row--header {
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .audit-form .table__row--header {
    display: none;
  }
}

.audit-form .table__row--header .table__col {
  padding-bottom: 1.2rem;
  background-color: #f8f8f8;
  color: #7bbc00;
}

.audit-form .table__row--footer {
  font-weight: 600;
}

.audit-form .table__row--footer .table__col {
  background-color: #e1f0d8;
}

.audit-form .table__col {
  width: 19%;
  margin-right: 5px;
  margin-bottom: 5px;
  float: left;
  background-color: #e3e3e3;
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: "Open Sans", sans-serif;
  padding: 0.5rem;
}

.audit-form .table__col strong {
  font-weight: 600;
}

.audit-form .table__col .besparing {
  color: #7bbc00;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .audit-form .table__col {
    clear: both;
    width: 100%;
  }
  .audit-form .table__col:before {
    content: attr(data-label);
    float: left;
    margin-right: 5px;
  }
}

.audit-form .table__col.table__col--header {
  width: 19%;
  float: left;
}

@media screen and (max-width: 800px) {
  .audit-form .table__col.table__col--header {
    width: 100%;
  }
}

.webform__result-pdf {
  display: none;
  padding: 3rem 2rem;
}

.webform__result-pdf .form-item {
  float: left;
  position: relative;
  width: 100%;
}

.webform__result-pdf .webform-component-email {
  clear: both;
  width: calc(45% - 30px);
  margin-right: 5%;
}

.webform__result-pdf .webform-component-email input {
  padding-left: 30%;
}

.webform__result-pdf .webform-component--required {
  display: inline-block;
  width: auto;
  float: left;
}

.webform__result-pdf .webform-component--required p {
  font-size: 1.4rem;
  font-style: italic;
  margin: 0;
}

.webform__result-pdf .webform-pdf__footer {
  margin-left: -2rem;
}

.webform__result-pdf .webform-pdf__footer .webform-component {
  margin: 1.4rem 0 0;
  font-size: 1.8rem;
}

.webform__result-pdf .webform-pdf__footer .webform-component p {
  margin: 0;
}

.webform__result-pdf .webform-pdf__footer .webform-component strong {
  font-weight: 700;
}

.webform__result-pdf .webform-component-markup {
  margin-bottom: 3.5rem;
  margin-top: 1.5em;
}

.webform__result-pdf .webform-component--download-intro {
  font-size: 1.75rem;
}

.webform__result-pdf .webform-component--download-intro h2 {
  text-transform: uppercase;
}

.webform__result-pdf .webform-component-markup.webform-component--download-pdf-button {
  display: inline-block;
  width: auto;
  margin-top: 0;
}

.webform__result-pdf .webform-component-markup.webform-component--download-pdf-button p {
  display: inline-block;
  margin-top: 0;
}

.webform__result-pdf .webform-component-markup.webform-component--download-pdf-button a {
  padding: 1.4rem 3.8rem 1.4rem 2.5rem;
  border: none;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 600;
}

.webform__result-pdf strong {
  font-weight: 700;
}

.pdf-contents .webform-component--pdf-group {
  background-color: #fff;
}

.pdf-contents .webform-component--pdf-group .webform-component--pdf-group--succes-box {
  color: #FFF;
  font-size: 1.75rem;
  display: none;
  background-color: #7bbc00;
  padding: 3rem;
  line-height: 1.2;
}

.pdf-contents .webform-component--pdf-group .webform-component--pdf-group--succes-box h2 {
  font-size: 2.4rem;
  margin-bottom: 0.8rem;
  font-weight: 700;
  color: #FFF;
}

.pdf-contents .webform-component--pdf-group .webform-component--pdf-group--succes-box p {
  display: block;
  clear: both;
  float: left;
  margin: 0;
  color: #FFF;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.2;
}

.pdf-contents .webform-component--pdf-group .webform-component--pdf-group--succes-box div {
  float: left;
  display: inline;
  font-family: "Open Sans", sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.2;
}

.pdf-contents .webform-component--pdf-group .webform-component--pdf-group--succes-box:before {
  content: none;
}

.pdf--header {
  display: none;
  margin-bottom: 1rem;
  padding: 3rem 2rem 2rem 2rem;
}

.pdf--header .pdf--logo {
  float: left;
}

.pdf--header .pdf--slogan {
  float: right;
  font-size: 3.4rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  line-height: 1.2;
}

.pdf--footer {
  display: none;
  padding: 2rem 2rem 3rem 2rem;
}

.pdf--footer p {
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
}

.pdf--footer strong {
  font-weight: 700;
}

.node--webform-confirmation__content {
  padding: 4rem;
  background-color: #272727;
}

.node--webform-confirmation__content p, .node--webform-confirmation__content a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.7rem;
  color: #ffffff;
  line-height: 1.2;
}

.node--webform-confirmation__content p {
  margin-bottom: 1.5rem;
}

.node--webform-confirmation__content a {
  color: #7bbc00;
}

.video-block {
  margin-top: 5rem;
}

.video-block:first-child {
  margin-top: 0;
}

.video-block__content {
  width: 100%;
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.3);
  position: relative;
}

.video-block__content:hover .circle-outer, .video-block__content:hover .circle-outer--white {
  stroke-dashoffset: 0;
  -webkit-animation-play-state: paused;
}

.video-block__content:hover .video-text__play-container:before {
  background-color: #7bbc00;
  transition: background-color 0.25s 0s;
}

.video-block__content:hover .video-text__play {
  fill: #ffffff;
  transition: fill 0.25s 0s;
}

.video-block__title {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #666666;
  font-weight: 400;
  font-style: italic;
  display: block;
  margin-top: 1.5rem;
}

.video-block__overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 50%;
}

.video-block__svg {
  width: 54px;
  height: 54px;
  fill: #7bbc00;
  display: block;
  transition: all 1s ease-in-out;
}

.video-text__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 601px) {
  .video-text__container {
    top: auto;
    bottom: 8rem;
  }
}

.video-text__container:hover .circle-outer, .video-text__container:hover .circle-outer--white {
  stroke-dashoffset: 0;
  -webkit-animation-play-state: paused;
}

.video-text__container:hover .video-text__play-container:before {
  background-color: #7bbc00;
  transition: background-color 0.25s 0s;
}

.video-text__container:hover .video-text__play {
  fill: #ffffff;
  transition: fill 0.25s 0s;
}

.video-text__play-container {
  border-radius: 50%;
  position: relative;
  width: 100px;
  height: 100px;
}

.video-text__play-container:before {
  content: "";
  height: 4rem;
  width: 4rem;
  background-color: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  transition: background-color 0.25s 0.55s;
}

.video-text__play {
  height: 50px;
  width: 50px;
  fill: #7bbc00;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: fill 0.25s 0.55s;
}

.circle-outer, .circle-outer--white {
  fill: transparent;
  stroke: #7bbc00;
  stroke-width: 14;
  stroke-dasharray: 534;
  transition: stroke-dashoffset 1.25s;
  -webkit-animation-play-state: running;
  transform: rotate(-90deg);
  transform-origin: center;
  stroke-dashoffset: 534;
  animation: show100;
}

.video-text__circle-outer, .video-text__circle-outer--white {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-outer--white {
  animation: none;
  stroke-dashoffset: 0;
  stroke: #ffffff;
  stroke-width: 22;
}

@keyframes show100 {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.view-display-id-search_two .view-content {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  color: #1b1b1b;
  line-height: 1.6;
}

.view-display-id-search_two .view-content .views-field-item-id {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.view-display-id-search_two .view-content a {
  font-size: 2.1rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.2;
  color: #7bbc00;
  font-weight: 600;
  display: block;
}

.view-display-id-search_two .view-content strong {
  font-weight: 600;
}

.view-display-id-search_two .view-filters {
  display: none;
}

.view-display-id-search_two .view-filters form,
.search-block form {
  float: left;
  max-width: 100%;
}

.view-display-id-search_two .view-filters input[type='text'],
.view-display-id-search_two .view-filters .error,
.search-block input[type='text'],
.search-block .error {
  max-width: 35rem;
  width: 100%;
  background: transparent;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  padding: 0.5rem 0;
  border-bottom: 1px solid #7bbc00;
  color: #000000 !important;
  font-weight: 600;
  font-style: normal;
  display: inline-block;
}

.view-display-id-search_two .view-filters input[type='text']::placeholder,
.view-display-id-search_two .view-filters .error::placeholder,
.search-block input[type='text']::placeholder,
.search-block .error::placeholder {
  color: #7bbc00;
  font-weight: 400;
}

.view-display-id-search_two .view-filters input[type="submit"],
.search-block input[type="submit"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  border: none;
  z-index: 2;
  background-color: transparent;
}

.view-display-id-search_two .view-filters .views-widget-filter-search_api_views_fulltext,
.search-block .views-widget-filter-search_api_views_fulltext {
  float: left;
}

.view-display-id-search_two .view-filters .views-submit-button,
.search-block .views-submit-button {
  float: left;
}

.search-block {
  float: left;
  line-height: 4.9rem;
  border-right: 1px solid #e0e0e0;
}

.search-block:hover .views-widget-filter-search_api_views_fulltext {
  max-width: 20rem;
  padding-left: 2rem;
  transition: all 0.5s ease-in-out;
}

.search-block input[type='text'] {
  max-width: 20rem;
}

.search-block .views-widget-filter-search_api_views_fulltext {
  width: 100%;
  max-width: 0;
  padding-left: 0;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 400px) {
  .search-block .views-widget-filter-search_api_views_fulltext {
    padding-left: 2rem;
    max-width: 10rem;
  }
  .search-block .views-widget-filter-search_api_views_fulltext:hover {
    max-width: 10rem;
  }
}

.search-block__logo {
  line-height: 4.9rem;
  width: 4.9rem;
  height: 4.9rem;
  position: relative;
  float: left;
}

.search-block__logo:hover {
  cursor: pointer;
}

.search-block__logo:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg fill='%23616161' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.81 24'%3E%3Cpath d='M23.81 21.65l-6.2-6.2a9.74 9.74 0 1 0-2.39 2.32L21.46 24zM2.85 9.73a6.88 6.88 0 1 1 6.88 6.88 6.88 6.88 0 0 1-6.88-6.88z'/%3E%3C/svg%3E");
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-block__content {
  float: left;
}

.node__navbar {
  background-color: white;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 13;
  transition: all 150ms ease-out 0s;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  top: 103px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .node__navbar {
    position: relative !important;
    border: none;
    top: 0 !important;
  }
}

.node__navbar ul {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.node__navbar li {
  display: inline-block;
  padding: 0 20px;
}

.node__navbar li.right {
  float: right;
}

.node__navbar a {
  color: #2f2f2f;
  text-decoration: none;
  text-transform: uppercase;
  transition: .25s ease-out;
  font-family: "Century Gothic";
  font-size: 1.4rem;
}

.node__navbar a:hover {
  color: #7bbc00;
}

.node__content .paragraphs-items > *:last-child {
  padding-bottom: 6rem;
}

.node__content .paragraphs-items > *:first-child {
  margin-top: 0;
}

.node-type-reference .node__content .paragraphs-items > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.paragraphs-items > *:not(.photogrids) {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: translateY(50px);
}

.paragraphs-items > *:not(.photogrids).fade-in {
  opacity: 1;
  transform: translateY(0);
}

.paragraphs-items > *:hover .field--paragraph__admin-id {
  opacity: 1;
}

.field--paragraph__admin-id {
  position: absolute;
  top: -20px;
  right: 1.618em;
  border: 0;
  background-color: transparent;
  text-transform: uppercase;
  color: #7bbc00 !important;
  padding: 5px;
  font-size: 1.1rem;
  text-decoration: underline;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  opacity: .1;
  transition: .25s ease-out;
}

.field--paragraph__admin-id:active {
  color: #6b6b6b !important;
}

.text {
  margin-top: 5.5rem;
}

.image {
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.25);
  width: calc(100% + 5rem);
  position: relative;
  left: -2.5rem;
  margin-top: 5.5rem;
}

.image img {
  width: 100%;
}

.domein-techniek-block {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
  margin-top: 0;
}

.domein-techniek-block__title {
  margin-bottom: 3rem;
}

.domein-techniek-block__text {
  margin-bottom: 4rem;
}

.line {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin-top: 6.5rem;
}

.view-display-id-related_references_block .view-content::after {
  clear: both;
  content: "";
  display: block;
}

.image-text {
  margin-top: 4.5rem;
}

.image-text .full-text, .image-text .view-empty {
  width: calc(100% - 381px - 3rem);
  float: left;
}

@media screen and (max-width: 734px) {
  .image-text .full-text, .image-text .view-empty {
    width: calc(50% - 3rem);
  }
}

@media screen and (max-width: 570px) {
  .image-text .full-text, .image-text .view-empty {
    width: 100%;
  }
}

.image-text__image {
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.25);
  float: left;
  margin-right: 3rem;
  position: relative;
  left: -2.5rem;
  float: left;
  width: 381px;
}

@media screen and (max-width: 734px) {
  .image-text__image {
    width: calc(50% - 2.5rem);
  }
}

@media screen and (max-width: 570px) {
  .image-text__image {
    width: calc(100% + 5rem);
    margin-right: -2.5rem;
    margin-bottom: 5rem;
  }
  .image-text__image img {
    width: 100%;
  }
}

.image-text--right .image-text__image {
  float: right;
  margin-left: 3rem;
  right: -2.5rem;
  margin-right: 0;
  position: relative;
  left: 2.5rem;
}

@media screen and (max-width: 461px) {
  .image-text--right .image-text__image {
    margin-left: 2rem;
  }
}

.photo-gallery {
  clear: both;
  margin-top: 5rem;
}

.photo-gallery__image {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
  position: relative;
}

.photo-gallery__image:last-child {
  margin-right: 0;
}

.photo-gallery__image:nth-child(3n) {
  margin-right: 0;
}

.photo-gallery__image:nth-child(3n+1) {
  clear: left;
}

.photo-gallery__image:nth-child(n+4) {
  margin-top: 2.3576520234%;
}

@media screen and (max-width: 460px) {
  .photo-gallery__image {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .photo-gallery__image:last-child {
    margin-right: 0;
  }
  .photo-gallery__image:nth-child(3n) {
    margin-right: 2.3576520234%;
  }
  .photo-gallery__image:nth-child(3n+1) {
    clear: none;
  }
  .photo-gallery__image:nth-child(2n) {
    margin-right: 0;
  }
  .photo-gallery__image:nth-child(2n+1) {
    clear: left;
  }
  .photo-gallery__image:nth-child(n+3) {
    margin-top: 2.3576520234%;
  }
}

.photo-gallery__image:hover .photo-gallery__overlay {
  opacity: 1;
}

.photo-gallery__image:hover .photo-gallery__icon-container {
  opacity: 1;
  transform: translateY(0);
}

.photo-gallery__text {
  color: #fff;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
}

.photo-gallery__overlay {
  align-items: center;
  background-color: rgba(116, 174, 36, 0.6);
  border: 1px solid #7bbc00;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: 2rem;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.photo-gallery__icon-container {
  background-color: #7bbc00;
  border-radius: 50%;
  height: 4.5rem;
  opacity: 0;
  position: relative;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-in-out 0.3s , transform 0.4s ease-in-out 0.1s;
  width: 4.5rem;
}

.photo-gallery__icon {
  fill: #ffffff;
  height: 1.6rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.6rem;
}

.photo-gallery__alt {
  color: #ffffff;
  font-family: "Century Gothic";
  font-size: 2.4rem;
  line-height: 1.4;
  margin-top: 2rem;
  opacity: 0;
  text-transform: uppercase;
  transform: translateY(100%);
  transition: opacity 0.3s ease-in-out 0.3s , transform 0.4s ease-in-out 0.1s;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .photo-gallery__alt {
    font-size: 2rem;
  }
}

.download-button {
  margin-top: 5rem;
}

.image-text-block {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  padding: 15rem 0;
  position: relative;
  margin-top: 5rem;
}

.image-text-block:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.38) 0%, rgba(255, 255, 255, 0.38) 100%);
}

.image-text-block__content {
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.25);
  background-color: #ffffff;
  position: relative;
  z-index: 2;
  padding: 5rem;
}

.image-text-block__title {
  font-size: 2.1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #1b1b1b;
  margin-bottom: 3.5rem;
}

.image-text-block__text {
  margin-bottom: 3rem;
}

.paragraph--video {
  margin-top: 3rem;
}

.photogrids {
  padding-bottom: 0 !important;
  background-size: cover;
  background-position: center;
  min-height: 62rem;
  margin-top: 5.5rem;
  background-attachment: fixed;
}

.photogrids__content {
  position: relative;
  margin-top: -21.2rem;
  padding: 2.5rem;
  padding-bottom: 4rem;
  transition: all 0.3s ease-in-out;
  width: calc(100% + 5rem);
  left: -2.5rem;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 680px) {
  .photogrids__content {
    padding-bottom: 0;
  }
}

.photogrid {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.photogrid:last-child {
  margin-right: 0;
}

.photogrid:nth-child(3n) {
  margin-right: 0;
}

.photogrid:nth-child(3n+1) {
  clear: left;
}

@media screen and (max-width: 680px) {
  .photogrid {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .photogrid:last-child {
    margin-right: 0;
  }
}

.photogrid:after {
  content: "";
  box-shadow: 0px 0px 30px 0px rgba(29, 29, 29, 0.14);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

a.photogrid:hover:after {
  opacity: 1;
}

@media screen and (max-width: 680px) {
  a.photogrid:hover:after {
    opacity: 0;
  }
}

a.photogrid:hover {
  background-color: #42680d;
}

@media screen and (max-width: 680px) {
  a.photogrid:hover {
    background-color: #ffffff;
  }
}

a.photogrid:hover .photogrid__subtitle {
  color: #ffffff;
}

@media screen and (max-width: 680px) {
  a.photogrid:hover .photogrid__subtitle {
    color: #393939;
  }
}

.photogrid__image {
  height: 18.7rem;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  padding: 5.5rem 4.5rem;
}

.photogrid__image:after {
  display: block;
  width: 100%;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: rgba(0, 0, 0, 0.3);
}

.photogrid__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.8rem;
  line-height: 1.3;
  width: 100%;
  position: relative;
  z-index: 2;
}

.photogrid__title:after {
  content: "";
  width: 3rem;
  height: 0.3rem;
  background-color: #ffffff;
  margin-top: 2rem;
  display: block;
}

.photogrid__subtitle {
  transition: all 0.25s ease-in-out;
  padding: 3rem;
  padding-bottom: 5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  color: #393939;
  line-height: 1.4;
}

@media screen and (max-width: 680px) {
  .photogrid__subtitle {
    padding-bottom: 3rem;
  }
}

.paragraph--button {
  margin-top: 3rem;
}

.icons {
  margin-top: 6rem;
}

.icon {
  width: 25%;
  float: left;
  text-align: center;
}

.icon:nth-child(n+5) {
  margin-top: 5rem;
}

@media screen and (max-width: 737px) {
  .icon {
    width: 33.3333333%;
  }
  .icon:nth-child(2n+4) {
    clear: both;
  }
  .icon:nth-child(n+4) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 489px) {
  .icon {
    width: 50%;
  }
  .icon:nth-child(2n+4) {
    clear: none;
  }
  .icon:nth-child(2n+3) {
    clear: both;
  }
  .icon:nth-child(n+3) {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 344px) {
  .icon {
    width: 100%;
    clear: both;
    margin-top: 3rem;
  }
  .icon:first-child {
    margin-top: 0;
  }
}

.icon__container {
  width: 14rem;
  height: 14rem;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #7bbc00;
}

.icon__container i {
  font-size: 6rem;
  color: #7bbc00;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out;
}

.icon__title {
  margin-top: 2.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #1b1b1b;
}

.block__title {
  font-size: 2.1rem;
  font-family: "Century Gothic Bold";
  line-height: 1.2;
  text-transform: uppercase;
}

.block__title--1 {
  padding-top: 6.5rem;
  padding-bottom: 5.5rem;
}

.block__title--2 {
  padding-top: 11rem;
}

.newsletter__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.2;
  color: #272727;
  display: block;
  position: relative;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1500px) {
  .newsletter__title {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 1020px) {
  .newsletter__title {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 823px) {
  .newsletter__title {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 516px) {
  .newsletter__title {
    margin-bottom: 1rem;
  }
}

.newsletter__title:before {
  content: "";
  position: absolute;
  width: 3.6rem;
  height: 2.9rem;
  left: -4.2rem;
  top: 0.3rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 83.38 75.5'%3E%3Ctitle%3EMiddel 2%3C/title%3E%3Cpath d='M14.62,12a5,5,0,0,1-1-2.91V9A1.65,1.65,0,0,0,12,7.38a1.69,1.69,0,0,0-1.67,1.7,8.45,8.45,0,0,0,.13,1.43,1.67,1.67,0,0,0-.23,3.17,21.39,21.39,0,0,0,2.16.76h.06A1.67,1.67,0,0,0,14.62,12Z'/%3E%3Cpath d='M12.3,6.45a1.67,1.67,0,0,0,2.34-.35,5.06,5.06,0,0,1,2.52-1.81,1.67,1.67,0,0,0-1-3.19,8.44,8.44,0,0,0-4.19,3A1.67,1.67,0,0,0,12.3,6.45Z'/%3E%3Cpath d='M25.37,7.43A1.67,1.67,0,0,0,23.7,9.09a5,5,0,0,1-1,2.94,1.67,1.67,0,0,0,2.7,2A8.29,8.29,0,0,0,27,9.1a1.67,1.67,0,0,0-1.67-1.68Z'/%3E%3Cpath d='M20.26,4.31a5.06,5.06,0,0,1,2.5,1.84,1.67,1.67,0,0,0,2.71-2,8.43,8.43,0,0,0-4.16-3.06,1.67,1.67,0,0,0-1.05,3.18Z'/%3E%3Cpath d='M8,8.79A13.08,13.08,0,0,1,5.18,6.36,1.67,1.67,0,1,0,2.63,8.53a16.44,16.44,0,0,0,3.56,3.06A1.67,1.67,0,0,0,8,8.79Z'/%3E%3Cpath d='M3.55,3.33a6.71,6.71,0,0,1-.2-1.66A1.67,1.67,0,0,0,0,1.67,10.07,10.07,0,0,0,.31,4.16,1.67,1.67,0,0,0,1.93,5.42a1.65,1.65,0,0,0,.41-.05A1.67,1.67,0,0,0,3.55,3.33Z'/%3E%3Cpath d='M28.66,25s-.7-.26-1.73-.79a1.67,1.67,0,1,0-1.51,3,22,22,0,0,0,2.08.94A1.67,1.67,0,0,0,28.66,25Z'/%3E%3Cpath d='M23.62,22.25a18.62,18.62,0,0,1-2.84-2.52A1.67,1.67,0,1,0,18.29,22a21.91,21.91,0,0,0,3.35,3,1.67,1.67,0,1,0,2-2.7Z'/%3E%3Cpath d='M18.86,17.42A8.31,8.31,0,0,0,21.25,17a1.67,1.67,0,1,0-1-3.18,5.07,5.07,0,0,1-3.1,0,1.66,1.66,0,0,0-1,0l-.09,0a1.67,1.67,0,0,0-1.16,2.06,13.56,13.56,0,0,0,.84,2.23,1.67,1.67,0,0,0,3.17-.73Z'/%3E%3Cpath d='M83.13,73,62,24.09a1.67,1.67,0,0,0-1.91-1l-21.35,5-.05,0a1.6,1.6,0,0,0-.31.12l-.1.05a1.68,1.68,0,0,0-.26.19l-.06.05a1.71,1.71,0,0,0-.25.3l0,.08a1.62,1.62,0,0,0-.13.29l0,.06s0,0,0,.05a1.67,1.67,0,0,0-.05.33s0,0,0,.08V42.94L23.93,34.1a1.67,1.67,0,0,0-1.81,0L5.24,44.84a1.67,1.67,0,0,0,.32,3L81.09,75.39l.17.05a1.66,1.66,0,0,0,.43.06h0A1.67,1.67,0,0,0,83.13,73ZM9.89,45.84,23,37.49,68.58,67.26Zm30.92-.72V33.85L70.2,64.32Zm1.58-14.46,17.12-4L76.5,66Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 3rem;
}

@media screen and (max-width: 400px) {
  .newsletter__title:before {
    content: none;
  }
}

.newsletter {
  background-color: #f7f7f7;
  padding: 4rem 5rem 4rem 7rem;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
}

.newsletter:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1500px) {
  .newsletter {
    padding: 3rem 5rem 3rem 7rem;
  }
}

@media screen and (max-width: 1020px) {
  .newsletter {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    padding: 4rem 5rem 4rem 7rem;
  }
  .newsletter:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 516px) {
  .newsletter {
    padding: 3rem 5rem 3rem 7rem;
  }
}

@media screen and (max-width: 400px) {
  .newsletter {
    padding: 2rem;
  }
}

.newsletter input[type="email"] {
  border: none;
  border-bottom: 2px solid #7bbc00;
  background-color: transparent;
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding: 1rem 0;
  width: 22rem;
  color: #272727;
}

@media screen and (max-width: 1500px) {
  .newsletter input[type="email"] {
    width: 100%;
  }
}

.newsletter input[type="email"]::placeholder {
  font-style: italic;
  color: #9f9f9f;
}

.newsletter .webform-component--e-mail {
  float: left;
}

@media screen and (max-width: 823px) {
  .newsletter .webform-component--e-mail {
    width: 60%;
  }
}

@media screen and (max-width: 516px) {
  .newsletter .webform-component--e-mail {
    width: 100%;
  }
}

.newsletter .form-actions {
  float: right;
}

@media screen and (max-width: 1500px) {
  .newsletter .form-actions {
    clear: both;
    float: right;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 1020px) {
  .newsletter .form-actions {
    float: left;
    clear: none;
    margin-top: 0;
    margin-left: 2rem;
  }
}

@media screen and (max-width: 516px) {
  .newsletter .form-actions {
    clear: both;
    margin-top: 2rem;
    margin-left: 0;
  }
}

.newsletter .messages {
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: red;
  margin-bottom: 1rem;
}

.newsletter--social-media {
  margin-bottom: 2rem;
}

.newsletter--social-media .newsletter__title--social-media {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  font-family: "Open Sans", sans-serif;
  display: block;
}

.newsletter--social-media .newsletter__title--social-media:before {
  content: none;
}

.newsletter--social-media .newsletter__content::after {
  clear: both;
  content: "";
  display: block;
}

.newsletter--social-media .webform-component--e-mail {
  width: calc(100% - 12rem);
  float: left;
}

.newsletter--social-media input[type="email"] {
  background-color: #ebebeb;
  padding: 1rem 1rem;
  border: none;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  width: 100%;
}

.newsletter--social-media input[type="email"]::placeholder {
  color: #9f9f9f;
  font-style: italic;
}

.newsletter--social-media .form-actions {
  margin-left: 2rem;
  width: 10rem;
  float: left;
}

.logo-block {
  float: left;
  margin-top: -1.2rem;
  margin-left: 4rem;
}

@media screen and (max-width: 856px) {
  .logo-block {
    clear: both;
    float: left;
    margin-left: 0;
    margin-top: 3rem;
  }
}

.logo-block img {
  max-width: 18rem;
}

.logo-block--footer {
  float: left;
  margin-top: 0.2rem;
  margin-right: 19rem;
}

@media screen and (max-width: 1003px) {
  .logo-block--footer {
    margin-right: 3rem;
  }
}

.logo-block--footer .block__content > * {
  float: left;
}

.logo-block--footer img {
  max-width: 11.3rem;
  margin-top: -0.6rem;
}

.logo-block__text {
  float: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: #1e1e1e;
  margin-left: 2rem;
}

.view-empty {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  color: #1b1b1b;
  line-height: 1.6;
  margin-bottom: 2.5rem;
}

.node__name {
  font-family: "Century Gothic Bold";
  font-size: 2.1rem;
  line-height: 1;
  background-color: #7bbc00;
  color: #ffffff;
  padding: 0.6rem 0.7rem;
  display: inline-block;
}

.node__icon {
  float: left;
  padding-right: 1rem;
}

.node__icon img {
  height: 2.1rem;
  width: auto;
}

.node__title {
  font-family: "Century Gothic Bold";
  font-size: 6rem;
  line-height: 1.2;
  letter-spacing: -0.1rem;
  text-transform: uppercase;
  color: #000000;
  margin-top: 3rem;
}

@media screen and (max-width: 600px) {
  .node__title {
    font-size: 4rem;
  }
}

.node__title:after {
  content: "";
  clear: both;
  display: block;
  margin-top: 3.7rem;
  width: 5.8rem;
  height: 0.4rem;
  background-color: #7bbc00;
}

.node__intro {
  margin-top: 3.5rem;
}

.full-text > *:last-child, .view-empty > *:last-child {
  margin-bottom: 0;
}

.full-text p, .view-empty p {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  color: #1b1b1b;
  line-height: 1.6;
  margin-bottom: 2.5rem;
}

.full-text a, .view-empty a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #7bbc00;
  text-decoration: underline;
}

.full-text h1, .view-empty h1 {
  font-family: "Century Gothic Bold";
  color: #1b1b1b;
  font-size: 2.4rem;
  line-height: 1.2;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}

.full-text h2, .view-empty h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  color: #1b1b1b;
  line-height: 1.2;
  margin-bottom: 2.5rem;
}

.full-text h3, .view-empty h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  color: #1b1b1b;
  line-height: 1.2;
  margin-bottom: 2.5rem;
}

.full-text strong, .view-empty strong {
  font-weight: 700;
}

.full-text em, .view-empty em {
  font-style: italic;
}

.full-text ul, .view-empty ul, .full-text ol, .view-empty ol {
  margin-bottom: 2rem;
}

.full-text li, .view-empty li {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  color: #353535;
  line-height: 1.6;
  margin-top: 0.2rem;
  position: relative;
}

.full-text ul li ul, .view-empty ul li ul,
.full-text ol li ul, .view-empty ol li ul,
.full-text ul li ol, .view-empty ul li ol {
  margin-top: 0;
}

.full-text ul > li:first-child, .view-empty ul > li:first-child,
.full-text ol > li:first-child, .view-empty ol > li:first-child {
  margin-top: 0;
}

.full-text ul > li, .view-empty ul > li {
  position: relative;
  padding-left: 2rem;
}

.full-text ul > li:before, .view-empty ul > li:before {
  content: "";
  width: 1.6rem;
  height: 1.6rem;
  background-image: url("data:image/svg+xml,%3Csvg fill='%237bbc00' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 20'%3E%3Cpath d='M0,9.53,1.58,7.91l7.73,4.62L22.65,0,24,1.35,10,20Z'/%3E%3C/svg%3E");
  background-position: 0 0.3rem;
  background-size: 1.3rem;
  background-repeat: no-repeat;
  position: relative;
  top: 0.3rem;
  left: 0;
  position: absolute;
  margin-right: 0.5rem;
}

.full-text ol > li, .view-empty ol > li {
  counter-increment: step-counter;
  position: relative;
  padding-left: 2rem;
}

.full-text ol > li:before, .view-empty ol > li:before {
  content: counter(step-counter);
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Open Sans", sans-serif;
  color: #7bbc00;
  line-height: 1.6;
  font-weight: 700;
}

.full-text blockquote, .view-empty blockquote, .full-text q, .view-empty q {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 3.2rem;
  color: #7bbc00;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.full-text blockquote > *, .view-empty blockquote > *, .full-text q > *, .view-empty q > * {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 3.2rem;
  color: #7bbc00;
  margin-bottom: 0;
}

.full-text--large > *:last-child {
  margin-bottom: 0;
}

.full-text--large p {
  font-size: 2rem;
  line-height: 1.4;
}

.full-text--large a {
  font-size: 2rem;
  text-decoration: underline;
}

.full-text--large strong {
  font-weight: 700;
}

.full-text--large em {
  font-style: italic;
}

.full-text--large li {
  font-size: 2rem;
}

.full-text--large li:before {
  background-position: 0 0.6rem;
  background-size: 1.6rem;
}

.full-text--white > *:last-child {
  margin-bottom: 0;
}

.full-text--white p {
  color: #ffffff;
}

.full-text--white a {
  color: #ffffff;
}

.full-text--white h2 {
  color: #ffffff;
}

.full-text--white h3 {
  color: #ffffff;
}

.full-text--white strong {
  color: #ffffff;
}

.full-text--white em {
  color: #ffffff;
}

.full-text--white li {
  color: #ffffff;
}

.page-node-85,
.page-node-86,
.page-node-87,
.page-node-88 {
  background-color: #f7f7f7;
}

.page-node-85 .page,
.page-node-86 .page,
.page-node-87 .page,
.page-node-88 .page {
  background: #f7f7f7;
}

.page-node-85 .news-teaser--full,
.page-node-85 .spacer,
.page-node-86 .news-teaser--full,
.page-node-86 .spacer,
.page-node-87 .news-teaser--full,
.page-node-87 .spacer,
.page-node-88 .news-teaser--full,
.page-node-88 .spacer {
  background-color: #f7f7f7;
}

.page-node-85 .spacer,
.page-node-86 .spacer,
.page-node-87 .spacer,
.page-node-88 .spacer {
  height: 1rem;
}

.page-node-85 .spacer2,
.page-node-86 .spacer2,
.page-node-87 .spacer2,
.page-node-88 .spacer2 {
  height: 4rem;
  background-color: #f7f7f7;
}

.content__left {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}

.content__left:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1128px) {
  .content__left {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .content__left:last-child {
    margin-right: 0;
  }
}

.content__right {
  margin-left: 5.9708630347%;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 42.8503109536%;
  margin-right: 0;
}

.content__right:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1128px) {
  .content__right {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    margin-left: 0%;
    margin-top: 5rem;
  }
  .content__right:last-child {
    margin-right: 0;
  }
}

.video__video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url("../img.min/header_bg.png");
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.content-overlay {
  background-color: #ffffff;
  position: relative;
  z-index: 14;
}

.news-home {
  background-image: url("../img.min/homepage__background.png");
  background-repeat: no-repeat;
  background-position: center right;
  min-height: 50rem;
  background-position: right center;
  background-size: 61rem;
}

@media screen and (max-width: 960px) {
  .news-home {
    background-image: none;
  }
}

.news__back {
  display: block;
  margin-bottom: 3rem;
}

.news-teaser-home__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 2.1rem;
  line-height: 1.2;
  color: #272727;
  margin-top: -3px;
}

.news-teaser-home__link {
  display: block;
  margin-top: 1rem;
}

.news-teaser-home {
  display: block;
  clear: both;
  margin-top: 4rem;
}

.news-teaser-home:first-child {
  margin-top: 0;
}

.news-teaser-home:hover .news-teaser-home__link:after {
  animation: arrow-next 2s ease-in-out infinite;
}

.news-teaser-home__link,
.news-teaser-home__title {
  margin-left: 8rem;
}

.view-display-id-news_teaser_home {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}

.view-display-id-news_teaser_home:last-child {
  margin-right: 0;
}

@media screen and (max-width: 960px) {
  .view-display-id-news_teaser_home {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 74.4105869942%;
  }
  .view-display-id-news_teaser_home:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 660px) {
  .view-display-id-news_teaser_home {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .view-display-id-news_teaser_home:last-child {
    margin-right: 0;
  }
}

.view-display-id-news_teaser_home .more-link {
  float: left;
  clear: both;
  margin-top: 4rem;
}

.news__name {
  margin-right: 1.5rem;
}

.news-teaser--full {
  min-height: 60rem;
}

.news-teaser--full .c-blocks {
  padding-top: 0;
  margin-top: 0;
}

.news-teaser--full .view-filters__title {
  margin-top: 1.3rem;
  margin-right: 2rem;
  float: right;
}

.block-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
}

.homepage-block {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  position: relative;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
}

.homepage-block:after {
  content: "";
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.homepage-block:last-child {
  margin-right: 0;
}

.homepage-block:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1020px) {
  .homepage-block {
    margin-top: 3rem;
  }
  .homepage-block:nth-child(2) {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .homepage-block:nth-child(2):last-child {
    margin-right: 0;
  }
  .homepage-block:nth-child(3) {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
    margin-right: 0;
  }
  .homepage-block:nth-child(3):last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 544px) {
  .homepage-block:nth-child(2) {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .homepage-block:nth-child(2):last-child {
    margin-right: 0;
  }
  .homepage-block:nth-child(3) {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .homepage-block:nth-child(3):last-child {
    margin-right: 0;
  }
}

.homepage-block__cont {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 2;
}

.homepage-block__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #ffffff;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

.homepage-block__button {
  margin-top: auto;
}

.job-blocks {
  margin-top: 5rem;
}

@media screen and (max-width: 544px) {
  .job-blocks {
    margin-top: 2rem;
  }
}

.job-blocks .homepage-block__title {
  font-size: 2.5rem;
  margin-bottom: 1.7rem;
}

.job-blocks__content {
  display: flex;
  flex-wrap: wrap;
}

.job-block {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
  display: flex;
  padding: 3rem;
}

.job-block:after {
  content: none;
}

@media screen and (max-width: 544px) {
  .job-block {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
  }
  .job-block:last-child {
    margin-right: 0;
  }
}

.job-block:last-child {
  margin-right: 0;
}

.job-block:first-child {
  background-color: #afca0b;
}

.job-block:last-child {
  background-color: #42680d;
}

.job-block__cont {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reference__title:after {
  content: none;
}

.reference__cat-container {
  margin-top: 0.7rem;
}

.reference__cat-container:after {
  content: "";
  clear: both;
  display: block;
  margin-top: 3.7rem;
  width: 5.8rem;
  height: 0.4rem;
  background-color: #7bbc00;
}

.reference__cat {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  color: #ffffff;
  background-color: #000000;
  text-transform: none;
  font-style: italic;
  margin-right: 0.5rem;
  margin-top: 0.8rem;
}

.reference__video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  box-shadow: 0px 0px 45px 0px rgba(29, 29, 29, 0.3);
  width: calc(100% + 5rem);
  position: relative;
  left: -2.5rem;
}

.reference__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reference-home {
  position: relative;
}

.views-exposed-form--references {
  float: left;
}

.view-filter--reference form {
  float: left;
  max-width: 100%;
}

.views-exposed-widget--reference {
  float: left;
}

@media screen and (max-width: 759px) {
  .views-exposed-widget--reference {
    float: none;
  }
}

.view-filter--reference {
  background-color: #7bbc00;
}

@media screen and (max-width: 871px) {
  .view-filter--reference {
    background-color: #ffffff;
  }
}

.view-filters__title--reference {
  float: left;
  line-height: 1rem;
  font-size: 1.5rem;
  line-height: 5.1rem;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background-color: #000000;
  padding: 0 2rem;
  text-transform: uppercase;
}

@media screen and (max-width: 871px) {
  .view-filters__title--reference {
    width: 100%;
    background-color: #ffffff;
    color: #000000;
  }
}

@media screen and (max-width: 871px) {
  #views-exposed-form-reference-references-teaser-full-block {
    width: 100%;
    background-color: #7bbc00;
  }
}

.view-filter--reference {
  margin-bottom: 5rem;
}

.view-filter--reference .selectric {
  border: 1px solid #7bbc00;
  background: #7bbc00;
  border-right: 1px solid #a6d16a;
  max-width: 30rem;
  overflow: hidden;
}

@media screen and (max-width: 759px) {
  .view-filter--reference .selectric {
    border-right: 1px solid #7bbc00;
    border-bottom: 5px solid white;
  }
}

.view-filter--reference .selectric .label {
  color: #ffffff;
  font-size: 1.5rem;
  text-transform: none;
  text-align: center;
  position: relative;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin: 0 4rem 0 4rem;
}

.view-filter--reference .selectric .label:after {
  content: "";
  background: url("data:image/svg+xml,%3Csvg fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center;
  height: 1rem;
  display: inline-block;
  width: 3rem;
  transform: rotate(90deg);
}

.view-filter--reference .button {
  display: none;
}

.view-display-id-reference_teaser_home_attachment .slick-track {
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}

.view-display-id-reference_teaser_home .attachment,
.view-display-id-reference_teaser_home > .view-content > .slick-list {
  overflow: hidden;
}

.view-display-id-reference_teaser_home .attachment {
  padding-top: 3rem;
  margin-top: -3rem;
}

/* TEASER */
.domein-techniek-block .view-grouping {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
}

.domein-techniek-block .view-grouping:last-child {
  margin-right: 0;
}

.domein-techniek-block .view-grouping:nth-child(3n) {
  margin-right: 0;
}

.domein-techniek-block .view-grouping:nth-child(3n+1) {
  clear: left;
}

@media screen and (max-width: 800px) {
  .domein-techniek-block .view-grouping {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    margin-top: 3rem;
  }
  .domein-techniek-block .view-grouping:last-child {
    margin-right: 0;
  }
  .domein-techniek-block .view-grouping:first-child {
    margin-top: 0;
  }
}

.domein-techniek-block .view-grouping:first-child:last-child {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 100%;
  margin-right: 0;
}

.domein-techniek-block .view-grouping:first-child:last-child:last-child {
  margin-right: 0;
}

.domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
  margin-top: 0;
}

.domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser:last-child {
  margin-right: 0;
}

.domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser:nth-child(3n) {
  margin-right: 0;
}

.domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser:nth-child(3n+1) {
  clear: left;
}

.domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser:nth-child(n+4) {
  margin-top: 1.5rem;
}

@media screen and (max-width: 800px) {
  .domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    margin-top: 3rem;
  }
  .domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser:last-child {
    margin-right: 0;
  }
  .domein-techniek-block .view-grouping:first-child:last-child .f-block--teaser:first-child {
    margin-top: 0;
  }
}

.domein-techniek-block .view-grouping-header {
  position: relative;
  min-height: 4rem;
}

.domein-techniek-block .view-grouping-header::after {
  clear: both;
  content: "";
  display: block;
}

.domein-techniek-block .view-grouping-header img {
  height: 4rem;
  float: left;
  width: auto;
  position: relative;
  margin-right: 1.8rem;
}

.domein-techniek-block .view-grouping-content {
  margin-top: 3rem;
}

.domein {
  margin-top: 2rem;
}

.domein:first-child {
  margin-top: 0;
}

.domein__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1;
  color: #1b1b1b;
  padding-left: 5rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.whitepaper__overlay {
  position: relative;
  z-index: 10;
  margin-top: -50rem;
  width: 100%;
  height: 50rem;
  background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0.66) 49%, rgba(255, 255, 255, 0.5) 72%, rgba(255, 255, 255, 0) 100%);
}

.whitepaper__overlay::after {
  clear: both;
  content: "";
  display: block;
}

.sidebar-text {
  padding: 4rem;
  background-color: #f7f7f7;
}

.node__content--jobs {
  margin-bottom: 10rem;
}

.jobs__intro-text {
  margin-bottom: 5rem;
}

.related-jobs {
  clear: both;
}

.office--stickied .office__content {
  padding: 3.5rem;
  background-color: #f7f7f7;
}

.office--teaser {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}

.office--teaser:last-child {
  margin-right: 0;
}

.office--teaser:nth-child(2n) {
  margin-right: 0;
}

.office--teaser:nth-child(2n+1) {
  clear: left;
}

.office--teaser:nth-child(n+3) {
  margin-top: 6rem;
}

@media screen and (max-width: 455px) {
  .office--teaser {
    float: left;
    display: block;
    margin-right: 2.3576520234%;
    width: 100%;
    margin-right: 0;
    margin-top: 3rem;
  }
  .office--teaser:last-child {
    margin-right: 0;
  }
  .office--teaser:first-child {
    margin-top: 0;
  }
}

.office--teaser .office__address {
  margin-bottom: 2rem;
}

.view-display-id-office_block > .view-content {
  padding: 3.5rem;
  padding-top: 4.5rem;
}

.view-display-id-office_block > .view-content::after {
  clear: both;
  content: "";
  display: block;
}

.office .locality-block {
  float: left;
}

.office .country:before {
  content: ",";
  padding-right: 0.3rem;
}

.office__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 1.3;
  color: #1b1b1b;
  margin-bottom: 3rem;
}

.office__subtitle {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.3;
  color: #1b1b1b;
  margin-bottom: 2rem;
}

.office__left {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
}

.office__left:last-child {
  margin-right: 0;
}

.office__right {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
  margin-right: 0;
}

.office__right:last-child {
  margin-right: 0;
}

.office__prefix {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #7bbc00;
  clear: both;
  float: left;
  padding-right: 0.5rem;
}

.office__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #1b1b1b;
  display: block;
}

.office__bottom {
  clear: both;
  float: left;
  margin-top: 2rem;
}

.office__vat {
  clear: both;
  display: block;
}

.office__button {
  clear: both;
  display: block;
  float: left;
  margin-top: 2rem;
}

.node__content--contact {
  margin-bottom: 10rem;
}

.teaser-text {
  margin-top: 4.5rem;
}

.teaser-text .paragraphs-items {
  display: none;
}

.teaser-text .paragraphs-items > *:not(.photogrids) {
  opacity: 1;
  transform: translateY(0);
}

.teaser-text .paragraphs-items > *:not(.photogrids):last-child {
  padding-bottom: 2rem;
}

.teaser-text--visible .teaser-text__button:before {
  transform: rotate(-90deg);
}

.teaser-text__title {
  margin-bottom: 2.5rem;
  font-size: 2.4rem;
}

.teaser-text__intro {
  margin-bottom: 3rem;
}

.teaser-text__button {
  margin-top: 1.5rem;
}

.teaser-text:not(.teaser-text--visible) .teaser-text__button {
  display: block;
}

.teaser-text--visible .teaser-text__button {
  display: none;
}

/*# sourceMappingURL=main.css.map */